import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTable } from "react-table";

import PageHeader from "../../../components/PageHeader";
import Loader from "../../../components/Loader";
import BlankState from "../../../components/BlankState";

import Financial from "./../../../assets/images/finance.svg";

import { getFinancialMetrics } from "../actions";

import "./index.scss";

export default () => {
  const dispatch = useDispatch();
  const fundFinancialMetric = useSelector((state) => state.fundFinancialMetric);
  const layout = useSelector((state) => state.layout);
  const metric = fundFinancialMetric.metricData;

  console.log("metric", metric);
  const columns = useMemo(() => {
    return (metric.header || []).map((header, index) => ({
      Header: header,
      accessor: `${index}`,
    }));
  }, [metric.header]);

  const data = useMemo(() => {
    return metric.dataRows || [];
  }, [metric.dataRows]);

  useEffect(() => {
    console.log("coming here");
    console.log(layout.fund);
    if (layout.fund.basic && layout.fund.basic.id) {
      console.log(layout.fund.basic.id, "coming inside");
      dispatch(getFinancialMetrics(layout.fund.basic.id));
    }
  }, [dispatch, layout.fund]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  if (fundFinancialMetric.isFetchingFinancialMetricesList) {
    return (
      <div className="financial-metric__loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="financial-metric">
      <PageHeader
        icon={<ion-icon name="bar-chart-outline"></ion-icon>}
        title="View Metrics"
        subtitle="Past data"
      ></PageHeader>

      {/* <div className="financial-metric__table__filter">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div> */}

      {data && data.length ? (
        <div className="financial-metric__table">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      console.log(cell, "cell");
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.value?.includes("s3") ? (
                            <a onClick={() => window.open(cell.value)}>
                              View File
                            </a>
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <BlankState
          text="Past data does not exist"
          icon={<img src={Financial} alt="No financial"></img>}
        />
      )}
    </div>
  );
};
