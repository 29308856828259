import React from 'react';
import cx from 'classnames';

import './index.scss';

export default ({ list, activeIndex, handleClick }) => {
  return (
    <div className="stepper">
      {list && list.length === 1 ? (
        <p className="subtitle stepper__text">{list[0]}</p>
      ) : (
        <div className="stepper__header">
          {list.map((step, index) => {
            return (
              <>
                <div className="stepper__header__step">
                  <button
                    className={cx('step', {
                      active: index <= activeIndex,
                    })}
                    onClick={() => handleClick(index)}
                  >
                    {index < activeIndex ? (
                      <ion-icon name="checkmark-outline"></ion-icon>
                    ) : (
                      <label className="labelText">{index + 1}</label>
                    )}
                  </button>
                  <p className="captionText stepper__header__step__text">
                    {step}
                  </p>
                </div>
                {index < list.length - 1 ? <hr className="seperator" /> : null}
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};
