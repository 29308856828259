import { combineReducers } from 'redux';
import login from '../features/login/reducers';
import forgotPassword from '../features/forgotPassword/reducers';
import resetPassword from '../features/resetPassword/reducers';

import userAdd from '../features/usersAdd/reducers';
import users from '../features/users/reducers';
import layout from '../features/layout/reducers';
import companyAdd from '../features/companyAdd/reducers';
import fundAdd from '../features/fundsAdd/reducers';
import companies from '../features/companies/reducers';
import funds from '../features/funds/reducers';
import changePassword from './../features/changePassword/reducers';
import financialMetric from './../features/metrices/reducers';
import fundFinancialMetric from './../features/fundMetrices/reducers';
import metricForm from './../features/metricForm/reducers';
import fundMetricForm from './../features/fundMetricForm/reducers';
import templates from './../features/templates/reducers';
import templatesData from './../features/templateForm/reducers';

const appReducer = combineReducers({
  login,
  forgotPassword,
  userAdd,
  users,
  layout,
  companyAdd,
  companies,
  changePassword,
  resetPassword,
  financialMetric,
  fundFinancialMetric,
  templates,
  templatesData,
  metricForm,
  fundMetricForm,
  fundAdd,
  funds
});

export default (state, action) => {
  if (action.type === 'USER_LOGOUT_SUCCESS') {
    state = undefined;
  }

  return appReducer(state, action);
};
