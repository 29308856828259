import React from 'react';
import { useSelector } from 'react-redux';

import BlankState from '../../../components/BlankState';

import NoData from './../../../assets/images/no-data.svg';

import './index.scss';

export default () => {
  const selectedCompany = useSelector((state) => state.layout.company);

  if (
    !selectedCompany ||
    !selectedCompany.basic ||
    !selectedCompany.basic.googleDataStudioURL
  ) {
    return (
      <div className="dashboard__blank">
        <BlankState
          text="No data available"
          icon={<img src={NoData} alt="No data"></img>}
        />
      </div>
    );
  }

  return (
    <div className="dashboard">
      <iframe
        title="Dashboard"
        className="dashboard__frame"
        src={selectedCompany.basic.googleDataStudioURL}
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  );
};
