export default (
  state = {
    isFetchingCompany: false,
    company: {},
    isFetchingFund: false,
    fund: {},
  },
  action
) => {
  switch (action.type) {
    case 'GET_CURRENT_COMPANY_BY_ID_LOADING':
      return {
        ...state,
        isFetchingCompany: true,
      };

    case 'GET_CURRENT_COMPANY_BY_ID':
      return {
        ...state,
        company: !action.error ? action.payload : {},
        isFetchingCompany: false,
      };
    case 'GET_CURRENT_FUND_BY_ID_LOADING':
      return {
        ...state,
        isFetchingFund: true,
      };

    case 'GET_CURRENT_FUND_BY_ID':
      return {
        ...state,
        fund: !action.error ? action.payload : {},
        isFetchingFund: false,
      };

    default:
      return state;
  }
};
