import React from 'react';

import Button from './../Button';
import Select from './../Select';
import Input from './../Input';

import fieldsType from './../../constants/fieldsType';
import currencyType from './../../constants/currencyType';

import './index.scss';

export default ({
  handleSubmit,
  handleInputChange,
  formData,
  errors,
  isEditMode,
  handleReset,
  isFormEdit,
  pastDataFields = [],
}) => {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <h2 className="subtitle">Add a field</h2>
      <div className="form-control">
        <label className="labelText">Key</label>
        <Input
          type="text"
          name="key"
          onChange={handleInputChange}
          value={formData.key}
          error={errors.key}
          disabled={isEditMode && isFormEdit}
        />
      </div>
      <div className="form-control">
        <label className="labelText">Name</label>
        <Input
          type="text"
          name="name"
          onChange={handleInputChange}
          value={formData.name}
          error={errors.name}
        />
      </div>
      <div className="form-control">
        <label className="labelText">Description</label>
        <Input
          type="text"
          name="description"
          onChange={handleInputChange}
          value={formData.description}
          error={errors.description}
        />
      </div>
      <div className="form-control">
        <label className="labelText">Type</label>
        <Select
          options={fieldsType}
          name="type"
          onChange={handleInputChange}
          value={formData.type}
          error={errors.type}
        />
      </div>
      {formData.type === 'dropdown' ? (
        <>
          <div className="form-control">
            <label className="labelText">Options</label>
            <Input
              type="textarea"
              name="options"
              onChange={handleInputChange}
              value={formData.options}
              helperText="Use | operator to separate options"
              error={errors.options}
            />
          </div>
          <div className="form-control">
            <div className="check">
              <input
                type="checkbox"
                onChange={handleInputChange}
                name="isMulti"
                id="is-multi"
                checked={formData.isMulti}
              />

              <label className="labelText" htmlFor="is-multi">
                Multi Select
              </label>
            </div>
          </div>
        </>
      ) : null}

      {formData.type === 'amount' ? (
        <div className="form-control">
          <label className="labelText">Currency</label>
          <Select
            options={currencyType}
            name="currency"
            onChange={handleInputChange}
            value={formData.currency}
            error={errors.currency}
            getOptionValue={(o) => o.value}
          />
        </div>
      ) : null}

      {pastDataFields && pastDataFields.length ? (
        <>
          <div className="form-control">
            <label className="labelText">Past Data Field</label>
            <Select
              options={pastDataFields
                .filter((record) => !record.isPast)
                .map((record) => ({
                  value: record.key,
                  label: record.name,
                  type: record.type,
                  currency: record.currency ? record.currency : '',
                }))}
              name="pastDataKey"
              onChange={(e) => {
                handleInputChange({
                  ...e,
                  target: {
                    ...e.target,
                    type: 'pastKey',
                  },
                });
              }}
              value={formData.pastDataKey}
            />
          </div>
          <div className="form-control">
            <label className="labelText">Past Data Hint Text</label>
            <Input
              type="text"
              name="pastDataHintText"
              onChange={handleInputChange}
              value={formData.pastDataHintText}
              error={errors.pastDataHintText}
              helperText="Use {reportingDate} for past data date and {label} for label"
            />
          </div>
        </>
      ) : null}

      <div className="form-control">
        <div className="check">
          <input
            type="checkbox"
            onChange={handleInputChange}
            name="isRequired"
            id="is-required"
            checked={formData.isRequired}
          />

          <label className="labelText" htmlFor="is-required">
            Required
          </label>
        </div>
      </div>
      <div className="form-control">
        <Button type="submit" size="lg" variant="outlined">
          Save field
        </Button>
        {isEditMode ? (
          <Button type="button" size="lg" variant="link" onClick={handleReset}>
            Reset
          </Button>
        ) : null}
      </div>
    </form>
  );
};
