export default (
  state = {
    fund: {},
    selectedFund: {},
    isPostFundSuccess: false,
    isPostFundFail: false,
    updatedFund: {},
    deletedFund: {},
    isUpdatingFund: false,
    isPostingFund: false,
    isUpdatingFundFail: false,
    isUpdatingFundSuccess: false,
    isDeleteingFundSuccess: false,
    isDeleteingFundFail: false,
  },
  action
) => {
  switch (action.type) {
    case 'POST_FUND_START':
      return {
        ...state,
        isPostingFund: true,
        isPostFundSuccess: false,
        isPostFundFail: false,
      };

    case 'POST_FUND_SUCCESS':
      return {
        ...state,
        isPostingFund: false,
        isPostFundSuccess: true,
        fund: action.data,
        selectedFund: {},
      };

    case 'POST_FUND_FAIL':
      return { ...state, isPostFundFail: true, isPostingFund: false };

    case 'RESET_POST_FUND_STATUS':
      return {
        ...state,
        isPostFundSuccess: false,
        isPostFundFail: false,
        isUpdatingFundFail: false,
        isUpdatingFundSuccess: false,
        isDeleteingFundSuccess: false,
        isDeleteingFundFail: false,
      };

    case 'GET_FUND_BY_ID_LOADING':
      return {
        ...state,
        isFetchingFundByID: true,
      };

    case 'GET_FUND_BY_ID':
      return {
        ...state,
        selectedFund: !action.error ? action.payload : {},
        isFetchingFundByID: false,
      };

    case 'UPDATE_FUND_LOADING':
      return {
        ...state,
        isUpdatingFund: true,
        isUpdatingFundSuccess: false,
        isUpdatingFundFail: false,
      };

    case 'UPDATE_FUND':
      return {
        ...state,
        updatedFund: !action.error ? action.payload : {},
        isUpdatingFund: false,
        isUpdatingFundSuccess: !action.error ? true : false,
        isUpdatingFundFail: action.error ? true : false,
      };

    case 'DELETE_FUND_LOADING':
      return {
        ...state,
        isDeleteingFund: true,
        isDeleteingFundSuccess: false,
        isDeleteingFundFail: false,
      };

    case 'DELETE_FUND':
      return {
        ...state,
        deletedFund: !action.error ? action.payload : {},
        isDeleteingFund: false,
        isDeleteingFundSuccess: !action.error ? true : false,
        isDeleteingFundFail: action.error ? true : false,
      };

    default:
      return state;
  }
};
