import React, { Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from './../Button';
import './index.scss';

const getIconByType = (type) => {
  switch (type) {
    case 'text':
      return <ion-icon name="text-outline"></ion-icon>;

    case 'textarea':
      return <ion-icon name="document-text-outline"></ion-icon>;

    case 'date':
      return <ion-icon name="calendar-outline"></ion-icon>;

    case 'number':
      return <ion-icon name="keypad-outline"></ion-icon>;

    default:
      return <ion-icon name="library-outline"></ion-icon>;
  }
};

export default ({ fields, onDragEnd, handleEdit, handleDelete }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {fields.map((field, index) => (
              <Fragment key={field.key}>
                {!field.isPast ? (
                  <Draggable
                    key={field.key}
                    draggableId={field.key}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="form-builder__container__fields__item">
                          {getIconByType(field.type)}

                          <div className="name">{field.name}</div>
                          {!field.isFixed ? (
                            <>
                              <Button
                                type="submit"
                                size="sm"
                                variant="outlined"
                                onClick={(e) => handleEdit(e, index)}
                              >
                                <ion-icon name="create-outline"></ion-icon>
                                Edit
                              </Button>
                              <Button
                                type="submit"
                                size="lg"
                                onClick={(e) => handleDelete(e, index)}
                                variant="icon"
                              >
                                <ion-icon name="trash-outline"></ion-icon>
                              </Button>
                            </>
                          ) : null}

                          {field.isRequired ? (
                            <div className="form-builder__container__fields__item__required">
                              Required
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ) : null}
              </Fragment>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
