import React from 'react';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './index.scss';

export default ({
  onChange,
  value,
  error,
  helperText = '',
  name,
  valueformat = 'MM/dd/yyyy',
  pastData = '',
  showMonthYearPicker,
  ...rest
}) => {
  return (
    <>
      <DatePicker
        {...rest}
        showMonthYearPicker={showMonthYearPicker}
        selected={value ? parse(value, valueformat, new Date()) : ''}
        dateFormat={valueformat}
        onChange={(date) => {
          let newDate = date;
          if (showMonthYearPicker) {
            newDate = lastDayOfMonth(newDate);
          }
          onChange({ target: { value: format(newDate, valueformat), name } });
        }}
        className="date-picker"
      />
      {error ? (
        <p className="select__error">{error}</p>
      ) : (
        <p className="select__help">{helperText}</p>
      )}
      <p className="select__help">{pastData}</p>
    </>
  );
};
