import axios from 'axios';

import config from '../../../config';

export const autoLogin = (preventLogout = false) => {
  return (dispatch) => {
    const token = localStorage.getItem(config.LOCAL_STORAGE_TOKEN_KEY);
    if (token) {
      dispatch(userLoginStart());
      dispatch(userLoginSuccess({ token }));
    } else {
      if (!preventLogout) {
        console.log('User not authorized. Redirecting to login...');
        dispatch(userLogout());
      }
    }
  };
};

const userLoginStart = () => {
  return {
    type: 'USER_LOGIN_START',
  };
};

const userLoginSuccess = ({ token }) => {
  localStorage.setItem(config.LOCAL_STORAGE_TOKEN_KEY, token);

  return {
    type: 'USER_LOGIN_SUCCESS',
    payload: token,
  };
};

const userLoginFail = (data) => {
  return {
    type: 'USER_LOGIN_FAIL',
    payload: data,
  };
};

export const userLogin = (data) => {
  return (dispatch) => {
    dispatch(userLoginStart());
    axios
      .post(`${config.API_URL}/user/login`, data)
      .then((resp) => {
        dispatch(userLoginSuccess({ token: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to login', ex.response);
        dispatch(userLoginFail(ex.response || ex.request));
      });
  };
};

export const userGoogleLogin = (token) => {
  return (dispatch) => {
    dispatch(userLoginStart());
    axios
      .post(`${config.API_URL}/user/googlelogin`, { token })
      .then((resp) => {
        dispatch(userLoginSuccess({ token: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to login using Google', ex);
        dispatch(userLoginFail());
      });
  };
};

const userLogoutStart = () => {
  return {
    type: 'USER_LOGOUT_START',
  };
};

const userLogoutSuccess = () => {
  return {
    type: 'USER_LOGOUT_SUCCESS',
  };
};

const userLogoutFail = () => {
  return {
    type: 'USER_LOGOUT_FAIL',
  };
};

export const userLogout = () => {
  return (dispatch, getState) => {
    dispatch(userLogoutStart());

    localStorage.removeItem(config.LOCAL_STORAGE_TOKEN_KEY);
    localStorage.removeItem(config.LOCAL_STORAGE_COMPANY_KEY);

    const { login } = getState();

    axios
      .delete(`${config.API_URL}/user/logout`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        console.log('resp: ', resp);
        dispatch(userLogoutSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to user logout', ex);
        dispatch(userLogoutFail());
      });
  };
};

// Fetch information of logged in user
export const getUser = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_USER_LOADING',
      payload: true,
    });

    const { login } = getState();
    axios
      .get(`${config.API_URL}/user`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: 'GET_USER',
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log('Failed to fetch current user info', ex);
        dispatch({
          type: 'GET_USER',
          payload: ex,
          error: true,
        });
      });
  };
};
