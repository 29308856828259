export default (
  state = {
    list: [],
  },
  action
) => {
  switch (action.type) {
    case 'GET_FUNDS_LIST_START':
      return { ...state, isFetchingFundsList: true };

    case 'GET_FUNDS_LIST_SUCCESS':
      return {
        ...state,
        isFetchingFundsList: false,
        list: action.data,
      };

    case 'GET_FUNDS_LIST_FAIL':
      return { ...state, isFetchingFundsList: false, list: [] };

    case 'SEND_REMINDER_LOADING':
      return {
        ...state,
        isSendingReminder: true,
        isSendingReminderSuccess: false,
        isSendingReminderFail: false,
      };

    case 'SEND_REMINDER':
      return {
        ...state,
        isSendingReminder: false,
        isSendingReminderSuccess: !action.error ? true : false,
        isSendingReminderFail: action.error ? true : false,
      };

    case 'RESET_REMINDER_STATUS':
      return {
        ...state,
        isSendingReminder: false,
        isSendingReminderSuccess: false,
        isSendingReminderFail: false,
      };
    default:
      return state;
  }
};
