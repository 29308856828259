import React from 'react';
import cx from 'classnames';

import './index.scss';

export default ({
  variant = 'contained',
  size = 'fluid',
  color = 'primary',
  children,
  className,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={cx(
        'button',
        className,
        `button--${size}`,
        `button--${color}`,
        `button--${variant}`
      )}
    >
      {children}
    </button>
  );
};
