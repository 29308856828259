import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Input from './../../../components/Input';
import Button from './../../../components/Button';
import StatusScreen from '../../../components/StatusScreen';

import { resetPassword, resetPasswordStatus } from './../actions';

import './index.scss';

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const resetPasswordData = useSelector((state) => state.resetPassword);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [status, setStatus] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusActions, setStatusActions] = useState([]);

  useEffect(() => {
    if (resetPasswordData.isResetPasswordSuccess) {
      setStatus(true);
      setStatusType('success');
      setStatusMessage('We have sent you an email to reset your password');
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);

            history.push('/login');
          }}
        >
          Back to login
        </Button>,
      ]);
      dispatch(resetPasswordStatus());
    } else if (resetPasswordData.isResetPasswordFail) {
      setStatus(true);
      setStatusType('error');
      setStatusMessage(resetPasswordData.message);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
          }}
        >
          Back
        </Button>,
      ]);
      dispatch(resetPasswordStatus());
    }
  }, [history, dispatch, resetPasswordData]);

  const validate = (values) => {
    let err = {};

    if (!values.password) {
      err.password = '*Please enter a password';
    }

    if (!values.confirmPassword || values.password !== values.confirmPassword) {
      err.confirmPassword = `*The two password that you've provided do not match`;
    }

    return err;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const err = validate(formData);
    setErrors(err);

    if (!Object.keys(err).length) {
      dispatch(resetPassword({ password: formData.password }, token));
    }

    return false;
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setErrors({
      ...errors,
      [event.target.name]: '',
    });
  };

  return (
    <>
      {status ? (
        <StatusScreen
          message={statusMessage}
          type={statusType}
          actions={statusActions}
          iconWidth={40}
          textStyle={'bodyText'}
        />
      ) : (
        <div className="reset-password">
          <div className="reset-password__title">
            <p className="title">Welcome</p>
            <p className="bodyText">Reset your password</p>
          </div>
          <form
            className="reset-password__form"
            onSubmit={handleFormSubmit}
            noValidate
          >
            <div className="form-control">
              <Input
                placeholder="New Password"
                type="password"
                name="password"
                onChange={handleInputChange}
                value={formData.password}
                prefix={<ion-icon name="lock-closed-outline"></ion-icon>}
                error={errors.password}
              />
            </div>
            <div className="form-control">
              <Input
                placeholder="Confirm New Password"
                type="password"
                name="confirmPassword"
                onChange={handleInputChange}
                value={formData.confirmPassword}
                prefix={<ion-icon name="lock-closed-outline"></ion-icon>}
                error={errors.confirmPassword}
              />
            </div>
            <div className="form-control">
              <Button type="submit">Save Password</Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
