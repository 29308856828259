export default (state = {
  changePasswordSuccess: false,
  changePasswordFail: false
}, action) => {
  switch (action.type) {
    case 'CHANGE_PASSWORD_START':
      return {
        ...state,
        changePasswordSuccess: false,
        changePasswordFail: false,
      };

    case 'CHANGE_PASSWORD_SUCCESS':
      return {
        ...state,
        changePasswordSuccess: true,
      };

    case 'CHANGE_PASSWORD_FAIL':
      return { ...state, changePasswordFail: true };

    default:
      return state;
  }
};
