export default (
  state = {
    isUserLoginSuccess: false,
    isUserLoginFail: false,
    token: '',
    isUserLogoutSuccess: false,
    isUserLogoutFail: false,
    isFetchingUser: false,
    user: {},
  },
  action
) => {
  switch (action.type) {
    case 'USER_LOGIN_START':
      return { ...state, isUserLoginSuccess: false, isUserLoginFail: false };

    case 'USER_LOGIN_SUCCESS':
      return {
        ...state,
        isUserLoginSuccess: true,
        isUserLogoutSuccess: false,
        isUserLogoutFail: false,
        token: action.payload,
      };

    case 'USER_LOGIN_FAIL':
      return {
        ...state,
        isUserLoginFail: true,
        message:
          action.payload?.status === 429
            ? action.payload.data.message
            : 'Invalid email or password',
      };

    case 'GET_USER_LOADING':
      return {
        ...state,
        isFetchingUser: true,
      };

    case 'GET_USER':
      return {
        ...state,
        user: !action.error ? action.payload : {},
        isFetchingUser: false,
      };

    case 'USER_LOGOUT_START':
      return { ...state, isUserLogoutSuccess: false, isUserLogoutFail: false };

    case 'USER_LOGOUT_SUCCESS':
      return {
        ...state,
        isUserLogoutSuccess: true,
        isUserLoginSuccess: false,
        isUserLoginFail: false,
      };

    case 'USER_LOGOUT_FAIL':
      return { ...state, isUserLogoutFail: true };

    default:
      return state;
  }
};
