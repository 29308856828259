import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import config from "../config";

import Auth from "../features/auth/components";
import Layout from "../features/layout/components";
import Metrices from "../features/metrices/components";
import FundMetrices from "../features/fundMetrices/components";
import MetricForm from "../features/metricForm/components";
import FundMetricForm from "../features/fundMetricForm/components";
import Users from "../features/users/components";
import UsersAdd from "../features/usersAdd/components";
import Companies from "../features/companies/components";
import Funds from "../features/funds/components";
import CompanyAdd from "../features/companyAdd/components";
import FundsAdd from "../features/fundsAdd/components";
import ChangePassword from "../features/changePassword/components";
import Templates from "../features/templates/components";
import TemplateForm from "../features/templateForm/components";
import Dashboard from "../features/dashboard/components";
import FundsDashboard from "../features/fundsDashboard/components";
import LPDashboard from "../features/LPDashboard/components";

import { autoLogin, getUser } from "../features/login/actions";

const PrivateRoute = ({ children, ...rest }) => {
  const login = useSelector((state) => state.login);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        login.isUserLoginSuccess ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default () => {
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [isVerifyingAuth, setAuthVerifyStatus] = useState(true);

  useEffect(() => {
    localStorage.removeItem(config.LOCAL_STORAGE_COMPANY_KEY);
  }, []);

  useEffect(() => {
    // Attempt auto-login
    dispatch(autoLogin());
  }, [dispatch]);

  useEffect(() => {
    if (login.isUserLoginSuccess) {
      dispatch(getUser());
    } else {
      dispatch(autoLogin(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login.isUserLoginSuccess]);

  useEffect(() => {
    setAuthVerifyStatus(false);
  }, [
    login.isUserLoginSuccess,
    login.isUserLoginFail,
    login.isUserLogoutSuccess,
  ]);

  if (isVerifyingAuth) {
    return null;
  }

  return (
    <Router>
      <Switch>
        <PrivateRoute path="/dashboard" exact>
          <Layout>
            <Dashboard />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/fill-metric" exact>
          <Layout>
            <MetricForm />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/funds-fill-metric" exact>
          <Layout>
            <FundMetricForm />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/metrics" exact>
          <Layout>
            <Metrices />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/users" exact>
          <Layout>
            <Users />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/users/add" exact>
          <Layout>
            <UsersAdd />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/users/edit/:userId" exact>
          <Layout>
            <UsersAdd />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/companies" exact>
          <Layout>
            <Companies />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/companies/add" exact>
          <Layout>
            <CompanyAdd />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/funds/add" exact>
          <Layout>
            <FundsAdd />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/funds-metrics" exact>
          <Layout>
            <FundMetrices />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/companies/edit/:companyId" exact>
          <Layout>
            <CompanyAdd />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/funds/edit/:fundId" exact>
          <Layout>
            <FundsAdd />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/change-password" exact>
          <Layout>
            <ChangePassword />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/templates" exact>
          <Layout>
            <Templates />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/templates/add" exact>
          <Layout>
            <TemplateForm />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/templates/edit/:templateId" exact>
          <Layout>
            <TemplateForm />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/funds" exact>
          <Layout>
            <Funds />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/funds-dashboard" exact>
          <Layout>
            <FundsDashboard />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/lp-dashboard" exact>
          <Layout>
            <LPDashboard />
          </Layout>
        </PrivateRoute>
        <Route path="/">
          <Auth />
        </Route>
      </Switch>
    </Router>
  );
};
