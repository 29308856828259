export default (
  state = {
    isResetPasswordSuccess: false,
    isResetPasswordFail: false,
  },
  action
) => {
  switch (action.type) {
    case 'RESET_PASSWORD_START':
      return {
        ...state,
        isResetPasswordSuccess: false,
        isResetPasswordFail: false,
      };

    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        isResetPasswordSuccess: true,
        data: action.data,
      };

    case 'RESET_PASSWORD_FAIL':
      return {
        ...state,
        isResetPasswordFail: true,
        message:
          action.payload.status === 429
            ? action.payload.data.message
            : 'Failed to reset your password',
      };

    case 'RESET_PASSWORD_STATUS':
      return {
        ...state,
        isResetPasswordSuccess: false,
        isResetPasswordFail: false,
      };

    default:
      return state;
  }
};
