import axios from 'axios';
import config from '../../../config';

const postMetricsStart = () => {
  return {
    type: 'POST_FUND_METRICS_START',
  };
};

const postMetricsSuccess = ({ data }) => {
  return {
    type: 'POST_FUND_METRICS_SUCCESS',
    data,
  };
};

const postMetricsFail = () => {
  return {
    type: 'POST_FUND_METRICS_FAIL',
  };
};

export function postMetrics(fundId, data) {
  return (dispatch, getState) => {
    dispatch(postMetricsStart());

    const { login } = getState();
    axios
      .post(`${config.API_URL}/funds/metrics/add/${fundId}`, data, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(postMetricsSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to post company', ex);
        dispatch(postMetricsFail());
      });
  };
}

export const resetMetricsStatus = () => {
  return (dispatch) => {
    dispatch({ type: 'RESET_FUND_METRICS_STATUS', payload: false });
  };
};

const getPastMetricStart = () => {
  return {
    type: 'GET_PAST_FUND_METRIC_START',
  };
};

const getPastMetricSuccess = ({ data }) => {
  return {
    type: 'GET_PAST_FUND_METRIC_SUCCESS',
    data,
  };
};

const getPastMetricFail = () => {
  return {
    type: 'GET_PAST_FUND_METRIC_FAIL',
  };
};

export function getPastMetric(fundId) {
  return (dispatch, getState) => {
    dispatch(getPastMetricStart());

    const { login } = getState();

    axios
      .get(`${config.API_URL}/funds/metrics/read/${fundId}/last`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(getPastMetricSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to get past metrics', ex);
        dispatch(getPastMetricFail());
      });
  };
}
