import axios from "axios";
import config from "../../../config";

const getUsersListStart = () => {
  return {
    type: "GET_USERS_LIST_START",
  };
};

const getUsersListSuccess = ({ data }) => {
  return {
    type: "GET_USERS_LIST_SUCCESS",
    data,
  };
};

const getUsersListFail = () => {
  return {
    type: "GET_USERS_LIST_FAIL",
  };
};

export function getUsersList() {
  return (dispatch, getState) => {
    dispatch(getUsersListStart());

    const { login } = getState();

    axios
      .get(`${config.API_URL}/user/all`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(getUsersListSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log("Failed to post user", ex);
        dispatch(getUsersListFail());
      });
  };
}

export const sendReminder = (userId, templateType, fundId) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SEND_REMINDER_LOADING",
      payload: true,
    });

    const { login } = getState();
    axios
      .post(
        `${config.API_URL}/user/${userId}/reminder`,
        {
          templateType,
          fundId,
        },
        {
          headers: {
            Authorization: `Bearer ${login.token}`,
          },
        }
      )
      .then((resp) => {
        dispatch({
          type: "SEND_REMINDER",
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log("Failed to send reminder", ex);
        dispatch({
          type: "SEND_REMINDER",
          payload: ex,
          error: true,
        });
      });
  };
};

export const resetReminderStatus = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_REMINDER_STATUS", payload: false });
  };
};
