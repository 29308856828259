export default (state = {
  metricData: {},
}, action) => {
  switch (action.type) {
    case 'GET_FUND_FINANCIAL_METRICS_START':
      return { ...state, isFetchingFinancialMetricesList: true };

    case 'GET_FUND_FINANCIAL_METRICS_SUCCESS':
      return {
        ...state,
        isFetchingFinancialMetricesList: false,
        metricData: action.data,
      };

    case 'GET_FUND_FINANCIAL_METRICS_FAIL':
      return { ...state, isFetchingFinancialMetricesList: false, metric:{} };

    default:
      return state;
  }
};
