import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { formatDistanceStrict } from 'date-fns';
import SearchInput, { createFilter } from 'react-search-input';

import Button from './../../../components/Button';
import PageHeader from '../../../components/PageHeader';
import BlankState from '../../../components/BlankState';

import Paper from './../../../assets/images/paper.svg';
import NoData from './../../../assets/images/no-data.svg';

import { getTemplates, copyTemplateById } from './../actions';
import { deleteTemplate } from './../../templateForm/actions';

import './index.scss';

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const templates = useSelector((state) => state.templates);
  const templateList = templates.list || [];

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);

  const handleEdit = (templateId) => (event) => {
    event.preventDefault();

    history.push(`/templates/edit/${templateId}`);
  };

  const handleDelete = (templateId) => (event) => {
    event.preventDefault();
    if (window.confirm('Are you sure you wish to delete this template?')) {
      dispatch(deleteTemplate(templateId));
    }
  };

  const handleCopy = (templateId) => (event) => {
    event.preventDefault();
    dispatch(copyTemplateById(templateId));

    history.push(`/templates/add`);
  };

  const searchUpdated = (term) => {
    setSearchTerm(term);
  };

  const filteredTemplateList = templateList.filter(
    createFilter(searchTerm, ['templateName'])
  );

  return (
    <div className="templates">
      <PageHeader
        icon={<ion-icon name="people-outline"></ion-icon>}
        title="Templates"
        subtitle="All created templates"
      >
        <Link to="/templates/add">
          <Button type="button">Create form template</Button>
        </Link>
      </PageHeader>

      <div className="templates__filter">
        <div className="filter">
          <ion-icon name="search-outline"></ion-icon>
          <SearchInput
            className="search-input"
            onChange={searchUpdated}
            placeholder="Search..."
          />
        </div>
      </div>

      {filteredTemplateList && filteredTemplateList.length ? (
        <div className="templates__container">
          <div className="templates__container__row">
            {filteredTemplateList.map((template) => (
              <div
                className="templates__container__row__item cards"
                key={template.id}
              >
                <div className="cards__header">
                  <img src={Paper} alt="template" />

                  <h1 className="labelText">{template.templateName}</h1>
                  <label className="captionText">
                    {template.createdAt
                      ? formatDistanceStrict(
                          new Date(template.createdAt),
                          new Date(),
                          { addSuffix: true }
                        )
                      : null}
                  </label>
                </div>

                <div className="cards__action">
                  <Button
                    type="submit"
                    size="sm"
                    className="btnEdit"
                    variant="icon"
                    onClick={handleEdit(template.id)}
                  >
                    <ion-icon name="create-outline"></ion-icon>
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    className="btnCopy"
                    variant="icon"
                    onClick={handleCopy(template.id)}
                  >
                    <ion-icon name="copy-outline"></ion-icon>
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    className="btnDelete"
                    variant="icon"
                    onClick={handleDelete(template.id)}
                  >
                    <ion-icon name="trash-outline"></ion-icon>
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <BlankState
          text="No template available"
          icon={<img src={NoData} alt="No template"></img>}
        />
      )}
    </div>
  );
};
