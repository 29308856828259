export default (state = {
  list: [],
}, action) => {
  switch (action.type) {
    case 'GET_USERS_LIST_START':
      return { ...state, isFetchingUsersList: true };

    case 'GET_USERS_LIST_SUCCESS':
      return {
        ...state,
        isFetchingUsersList: false,
        list: action.data,
      };

    case 'GET_USERS_LIST_FAIL':
      return { ...state, isFetchingUsersList: false, list: [] };

    default:
      return state;
  }
};
