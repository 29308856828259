import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTable } from 'react-table';

import PageHeader from '../../../components/PageHeader';
import Loader from '../../../components/Loader';
import BlankState from '../../../components/BlankState';

import Financial from './../../../assets/images/finance.svg';

import { getFinancialMetrics } from '../actions';

import './index.scss';

export default () => {
  const dispatch = useDispatch();
  const financialMetric = useSelector((state) => state.financialMetric);
  const layout = useSelector((state) => state.layout);
  const metric = financialMetric.metricData;
  const columns = useMemo(() => {
    return (metric.header || []).map((header, index) => ({
      Header: header,
      accessor: `${index}`,
    }));
  }, [metric.header]);

  const data = useMemo(() => {
    return metric.dataRows || [];
  }, [metric.dataRows]);

  useEffect(() => {
    if (layout.company.basic && layout.company.basic.id) {
      dispatch(getFinancialMetrics(layout.company.basic.id));
    }
  }, [dispatch, layout.company]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  if (financialMetric.isFetchingFinancialMetricesList) {
    return (
      <div className="financial-metric__loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="financial-metric">
      <PageHeader
        icon={<ion-icon name="bar-chart-outline"></ion-icon>}
        title="View Metrics"
        subtitle="Past data"
      ></PageHeader>

      {/* <div className="financial-metric__table__filter">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div> */}

      {data && data.length ? (
        <div className="financial-metric__table">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <BlankState
          text="Past data does not exist"
          icon={<img src={Financial} alt="No financial"></img>}
        />
      )}
    </div>
  );
};
