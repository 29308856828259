import React, { useState, useEffect } from "react";
import cx from "classnames";

import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";

import Button from "../../../components/Button";
import StatusScreen from "../../../components/StatusScreen";
import FormBuilder from "../../../components/FormBuilder";
import Modal from "../../../components/Modal";
import Loader from "../../../components/Loader";

import FundDetailsForm from "./FundDetailsForm";

import {
  postFund,
  resetPostFundStatus,
  getFundById,
  updateFund,
} from "./../actions";
import { getTemplates } from "./../../templates/actions";

import "./index.scss";

const emailValidate = (email) => {
  var pattern = /^((\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)\s*[,]{0,1}\s*)+$/i;

  if (!pattern.test(email)) {
    return false;
  }

  return true;
};

const blankFormData = {
  basic: {
    name: "",
    ownerName: "",
    founderEmail: "",
    partnerEmail: "",
    website: "",
    googleDataStudioURL: "",
    city: "",
    state: "",
    country: "",
  },
  sheets: {
    "FUND OVERVIEW": {
      order: 0,
      name: "Fund Overview",
      rows: [],
    },
    "INVESTMENT OVERVIEW": {
      order: 1,
      name: "Investment Overview",
      rows: [],
    },
    "INVESTMENT SCHEDULE": {
      order: 2,
      name: "Investment Schedule",
      rows: [],
    },
    "FUND METRICS": {
      order: 3,
      name: "Fund Metrics",
      rows: [],
    },
    "LP DATA": {
      order: 4,
      name: "LP Data",
      rows: [],
    },
    // COMMENTS: {
    //   order: 4,
    //   name: 'Comments',
    //   rows: [],
    // },
    MISCELLANEOUS: {
      order: 5,
      name: "Miscellaneous",
      rows: [],
    },
  },
};

const validateDetails = (values) => {
  let err = {};
  if (!values.name) {
    err.name = "*Please enter a name";
  }

  // if (!values.ownerName) {
  //   err.ownerName = "*Please enter a founder's name";
  // }

  // if (!values.ownerName) {
  //   err.ownerName = `*Please enter an Investor's name`;
  // }
  // partnerEmail is carrying the partnerName
  // if (!values.partnerEmail) {
  //   err.partnerEmail = `*Please enter a Partner's name`;
  // }

  return err;
};

const getTypeByIndex = (index) => {
  switch (index) {
    case 0:
      return "FUND OVERVIEW";
    case 1:
      return "INVESTMENT OVERVIEW";
    case 2:
      return "INVESTMENT SCHEDULE";
    case 3:
      return "FUND METRICS";
    // case 4:
    //   return 'COMMENTS';
    case 4:
      return "LP DATA";
    case 5:
      return "MISCELLANEOUS";

    default:
      return null;
  }
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const fundAdd = useSelector((state) => state.fundAdd);

  const templateList = useSelector((state) => state.templates.list || []);

  const selectedFund = useSelector((state) => state.fundAdd.selectedFund || {});

  const [status, setStatus] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusActions, setStatusActions] = useState([]);
  const [errors, setErrors] = useState({});
  const [openIndex, setOpenIndex] = useState(0);
  const [isEdit, setEditState] = useState(false);
  const [formData, setFormData] = useState(blankFormData);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [selectedTemplate, setTemplate] = useState(null);

  const formSheetKeys = [
    "FUND OVERVIEW",
    "INVESTMENT OVERVIEW",
    "INVESTMENT SCHEDULE",
    "FUND METRICS",
    "LP DATA",
    "MISCELLANEOUS",
  ];

  useEffect(() => {
    if (selectedFund && selectedFund.basic && selectedFund.basic.id && isEdit) {
      const newFormData = {
        basic: {
          name: selectedFund.basic.name || "",
          ownerName: "",
          ownerName: selectedFund.basic.ownerName,
          partnerEmail: selectedFund.basic.partnerEmail,
          website: selectedFund.basic.website,
          googleDataStudioURL: selectedFund.basic.googleDataStudioURL || "",
          country: selectedFund.basic.country,
        },
        sheets: selectedFund.sheets || {},
      };

      setFormData(newFormData);
    }
  }, [isEdit, selectedFund]);

  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (params.fundId) {
      setEditState(true);
      dispatch(getFundById(params.fundId));
    }
  }, [dispatch, params.fundId]);

  useEffect(() => {
    if (fundAdd.isPostFundSuccess && fundAdd.fund) {
      setStatus(true);
      setStatusType("success");
      setStatusMessage(`${fundAdd.fund.name} is successfully created`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          onClick={() => {
            setStatus(false);
            setFormData(blankFormData);
            setOpenIndex(0);
          }}
        >
          Add Another Fund
        </Button>,
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
            history.push("/funds");
          }}
        >
          Not Now
        </Button>,
      ]);
      dispatch(resetPostFundStatus());
    } else if (fundAdd.isPostFundfail) {
      setStatus(true);
      setStatusType("error");
      setStatusMessage(`Failed to create company`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
          }}
        >
          Back
        </Button>,
      ]);
      dispatch(resetPostFundStatus());
    }
    debugger;
    if (fundAdd.isUpdatingFundSuccess && fundAdd.updatedFund) {
      setStatus(true);
      setStatusType("success");
      setStatusMessage(`${fundAdd.updatedFund.name} is successfully updated`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
            history.push("/funds");
          }}
        >
          Go to list
        </Button>,
      ]);
      dispatch(resetPostFundStatus());
    } else if (fundAdd.isUpdatingFundFail) {
      setStatus(true);
      setStatusType("error");
      setStatusMessage(`Failed to update company`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
          }}
        >
          Back
        </Button>,
      ]);
      dispatch(resetPostFundStatus());
    }
  }, [dispatch, history, fundAdd]);

  const handleSubmitClick = (event) => {
    event.preventDefault();

    const err = validateDetails(formData.basic);
    setErrors(err);

    console.log(err, "err");

    if (Object.keys(err).length) {
      return;
    }

    if (isEdit && params.fundId) {
      dispatch(updateFund(params.fundId, formData));
    } else {
      dispatch(
        postFund({
          ...formData,
          sheets: {
            ...formData.sheets,
            "INVESTMENT OVERVIEW": {
              ...formData.sheets["INVESTMENT OVERVIEW"],
              rows: [
                {
                  key: "reportingDate",
                  name: "Reporting Date",
                  type: "month_year",
                  description: "Reporting date",
                  isRequired: true,
                  isMulti: false,
                  isFixed: true,
                  options: "",
                  pastDataKey: "",
                  pastDataHintText: "",
                },
                ...formData.sheets["INVESTMENT OVERVIEW"].rows,
              ],
            },
          },
        })
      );
    }
  };

  const handleSheetFieldChange = (type) => (data) => {
    setFormData({
      ...formData,
      sheets: {
        ...formData.sheets,
        [type]: {
          ...formData.sheets[type],
          rows: data,
        },
      },
    });
  };

  const handleSheetNameChange = (type) => (name) => {
    setFormData({
      ...formData,
      sheets: {
        ...formData.sheets,
        [type]: {
          ...formData.sheets[type],
          name: name,
        },
      },
    });
  };

  const handleSelectTemplate = () => {
    setModalVisibility(true);
  };

  const handleTemplate = () => {
    setModalVisibility(false);

    if (!selectedTemplate) return;

    const type = getTypeByIndex(openIndex);

    setFormData({
      ...formData,
      sheets: {
        ...formData.sheets,
        [type]: {
          ...formData.sheets[type],
          rows: [
            ...(formData.sheets[type] && formData.sheets[type].rows
              ? formData.sheets[type].rows
              : []),
            ...selectedTemplate.templateJson,
          ],
        },
      },
    });
    setTemplate(null);
  };

  const handleTemplateSave = (key, index) => () => {
    if (index === formSheetKeys.length - 1) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index + 1);
    }
  };

  const handleOpenCard = (cardIndex) => {
    setOpenIndex(cardIndex);
  };

  const renderFormCard = (sheetkey, index) => {
    const form = formData.sheets[sheetkey] || {};
    return (
      <div className="company-add__form__card">
        <Button
          variant="text-link"
          className="company-add__form__card__header"
          onClick={() => handleOpenCard(index)}
        >
          <h6 className="subtitle">{sheetkey}</h6>
          <label className="captionTextError">{errors[sheetkey]}</label>
        </Button>

        {openIndex === index ? (
          <FormBuilder
            key={sheetkey}
            isFormEdit={isEdit}
            fields={form.rows || []}
            formName={form.name || ""}
            onFieldChange={handleSheetFieldChange(sheetkey)}
            onSubmit={handleTemplateSave(sheetkey, index)}
            showAction={index !== formSheetKeys.length - 1}
            actionText={index === formSheetKeys.length - 1 ? "Save" : "Next"}
            headerLink={
              <Button
                variant="outlined"
                size="sm"
                className="company-add__form__card__header__link"
                onClick={() => handleSelectTemplate()}
              >
                Select Template
              </Button>
            }
            onOrderChange={handleSheetFieldChange(sheetkey)}
            allowNameEdit={true}
            onNameChange={handleSheetNameChange(sheetkey)}
          />
        ) : null}
      </div>
    );
  };

  if (fundAdd.isFetchingFundByID) {
    return (
      <div className="app-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="company-add">
      {status ? (
        <StatusScreen
          message={statusMessage}
          type={statusType}
          actions={statusActions}
        />
      ) : (
        <>
          <PageHeader
            icon={<ion-icon name="business-outline"></ion-icon>}
            title={isEdit ? "Edit Fund" : "Create a new fund"}
            subtitle={isEdit ? "Edit fund info" : "Fund Registration"}
          />
          <div className="company-add__form">
            <div className="company-add__form__card">
              <Button
                variant="text-link"
                className="company-add__form__card__header"
              >
                <ion-icon name="information-circle-outline"></ion-icon>
                <h6 className="subtitle">Fund Details</h6>
              </Button>

              <FundDetailsForm
                onChange={setFormData}
                errors={errors}
                formData={formData}
                onErrorChange={setErrors}
              />
            </div>

            {formSheetKeys.map(renderFormCard)}

            <div>
              <Button
                type="button"
                size="lg"
                onClick={handleSubmitClick}
                disabled={fundAdd.isUpdatingFund || fundAdd.isPostingFund}
              >
                {fundAdd.isUpdatingFund || fundAdd.isPostingFund ? (
                  "Loading..."
                ) : (
                  <>{isEdit ? "Update" : "Submit"}</>
                )}
              </Button>
            </div>
          </div>
        </>
      )}

      {modalVisibility ? (
        <Modal
          open={modalVisibility}
          onClose={() => setModalVisibility(false)}
          title="Select Template"
          actions={[
            <Button
              type="button"
              size="lg"
              variant="link"
              onClick={() => setModalVisibility(false)}
            >
              Cancel
            </Button>,
            <Button type="button" size="lg" onClick={handleTemplate}>
              Save
            </Button>,
          ]}
        >
          <div className="template-list">
            {templateList && !templateList.length ? (
              <p className="bodyText">No existing templates available</p>
            ) : (
              <ul>
                {templateList.map((template) => {
                  return (
                    <li
                      key={template.id}
                      className={cx({
                        selected:
                          selectedTemplate &&
                          selectedTemplate.id === template.id,
                      })}
                    >
                      {/* eslint-disable-next-line */}
                      <a onClick={() => setTemplate(template)}>
                        {template.templateName}
                      </a>
                      {selectedTemplate &&
                      selectedTemplate.id === template.id ? (
                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </Modal>
      ) : null}
    </div>
  );
};
