import axios from "axios";
import config from "../../../config";

const changePasswordStart = () => {
  return {
    type: "CHANGE_PASSWORD_START",
  };
};

const changePasswordSuccess = ({ data }) => {
  return {
    type: "CHANGE_PASSWORD_SUCCESS",
    data,
  };
};

const changePasswordFail = () => {
  return {
    type: "CHANGE_PASSWORD_FAIL",
  };
};

export function changePassword(data) {
  return (dispatch, getState) => {
    dispatch(changePasswordStart());

    const { login } = getState();
    axios
      .post(`${config.API_URL}/user/password`, data, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(changePasswordSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log("Failed to reset password", ex);
        dispatch(changePasswordFail());
      });
  };
}
