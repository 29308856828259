import axios from "axios";
import config from "../config";

// axios
// .get(`${config.API_URL}/user/all`, {
//   headers: {
//     Authorization: `Bearer ${login.token}`,
//   },
// })
// .then((resp) => {
//   dispatch(getUsersListSuccess({ data: resp.data }));
// })
// .catch((ex) => {
//   console.log("Failed to post user", ex);
//   dispatch(getUsersListFail());
// });

const getSignedUrl = async (fileName, fileType, token) => {
  try {
    const response = await axios.post(
      `${config.API_URL}/user/generate-upload-url`,
      {
        fileName,
        fileType,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Couldn't upload the file", error);
  }
};

const uploadFile = async (file, token) => {
  try {
    let formData = new FormData();
    formData.set("file", file);
    const response = await axios.post(
      `${config.API_URL}/user/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }      
    );
    return response.data;
  } catch (error) {
    console.error("Couldn't upload the file", error);
  }
};

export default async ({ file, token }) => {
  const uploadResponse = await uploadFile(file, token);
  console.log(uploadResponse, 'uploadResponse');
  return uploadResponse;
};
