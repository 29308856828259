import React from 'react';

import './index.scss';

export default ({ message, type, actions, iconWidth, textStyle="subtitle" }) => {
  const getStatusIcon = (type) => {
    switch (type) {
      case 'success':
        return (
          <div className="status__success">
            <ion-icon
              name="checkmark-circle-outline"
              style={{ fontSize: iconWidth }}
            ></ion-icon>
          </div>
        );
      case 'error':
        return (
          <div className="status__error">
            <ion-icon
              name="close-circle-outline"
              style={{ fontSize: iconWidth }}
            ></ion-icon>
          </div>
        );

      default:
        return null;
    }
  };
  return (
    <div className="status">
      {getStatusIcon(type)}
      <h1 className={textStyle} >{message}</h1>
      <div className="status__actions">{actions}</div>
    </div>
  );
};
