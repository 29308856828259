import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../../login/components';
import ForgotPassword from '../../forgotPassword/components';
import ResetPassword from '../../resetPassword/components';

import Logo from './../images/logo.svg';
import IllustrationLeft from './../images/illustration1.svg';
import IllustrationRight from './../images/illustration2.svg';

import './index.scss';

export default ({ match }) => {
  return (
    <div className="auth">
      <img src={IllustrationLeft} alt="" className="auth__illustration-left" />
      <img
        src={IllustrationRight}
        alt=""
        className="auth__illustration-right"
      />
      <div className="auth__card">
        <div className="auth__card__logo">
          <img src={Logo} alt="logo" width={120} />
        </div>

        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/forgot-password" exact>
            <ForgotPassword />
          </Route>
          <Route path="/reset-password" exact>
            <ResetPassword />
          </Route>

          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
