import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Input from './../../../components/Input';
import Button from './../../../components/Button';
import StatusScreen from '../../../components/StatusScreen';

import { forgotPassword, resetForgotPasswordStatus } from './../actions';

import './index.scss';

const emailValidate = (email) => {
  var pattern = new RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/
  );

  if (!pattern.test(email)) {
    return false;
  }
  return true;
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const forgotPasswordData = useSelector((state) => state.forgotPassword);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: '',
  });
  const [status, setStatus] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusActions, setStatusActions] = useState([]);

  useEffect(() => {
    if (forgotPasswordData.isForgotPasswordSuccess) {
      setStatus(true);
      setStatusType('success');
      setStatusMessage('We have sent you an email to reset your password');
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);

            history.push('/login');
          }}
        >
          Back to login
        </Button>,
      ]);
      dispatch(resetForgotPasswordStatus());
    } else if (forgotPasswordData.isForgotPasswordFail) {
      setStatus(true);
      setStatusType('error');
      setStatusMessage(forgotPasswordData.message);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
          }}
        >
          Back
        </Button>,
      ]);
      dispatch(resetForgotPasswordStatus());
    }
  }, [history, dispatch, forgotPasswordData]);

  const validate = (values) => {
    let err = {};
    if (!values.email) {
      err.email = 'Please enter an email';
    } else if (!emailValidate(values.email)) {
      err.email = 'Please enter a valid email address';
    }

    return err;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const err = validate(formData);
    setErrors(err);

    if (!Object.keys(err).length) {
      dispatch(forgotPassword(formData));
    }

    return false;
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setErrors({
      ...errors,
      [event.target.name]: '',
    });
  };

  return (
    <>
      {status ? (
        <StatusScreen
          message={statusMessage}
          type={statusType}
          actions={statusActions}
          iconWidth={40}
          textStyle={'bodyText'}
        />
      ) : (
        <div className="forgot-password">
          <div className="forgot-password__title">
            <p className="title">Welcome</p>
            <p className="bodyText">Reset your password</p>
          </div>
          <form
            className="forgot-password__form"
            onSubmit={handleFormSubmit}
            noValidate
          >
            <div className="form-control">
              <Input
                placeholder="Email Address"
                type="email"
                name="email"
                onChange={handleInputChange}
                value={formData.email}
                prefix={<ion-icon name="mail-outline"></ion-icon>}
                error={errors.email}
              />
            </div>
            <div className="form-control">
              <Button type="submit">Reset</Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
