import React, { useEffect, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import "./index.scss";

export default ({ name, onChange, disabled, helperText }) => {
  return (
    <>
      <input
        name={name}
        type="file"
        onChange={(e) => {
          console.log("onchange", e);
          onChange(e.target.files[0], e);
        }}
      />
      <p className="input__help">{helperText}</p>
    </>
  );
  // const [openPicker, data] = useDrivePicker({
  //   onCancel: () => console.log("User closed picker with close/cancel button"),
  // });

  // useEffect(() => {
  //   if (data) {
  //     onChange(data);
  //   }
  // }, [data]);

  // const handleOpenPicker = () => {
  //   openPicker({
  //     clientId:
  //       "937463720318-flkasrq94ed5ongljda8p8mbnguvntb5.apps.googleusercontent.com",
  //     developerKey: "AIzaSyBf61kqFwzD7EN1nPglnGSgdvmsAcX_4XM",
  //     token: 'ya29.A0AVA9y1v6Bg_gv7Fybv5MSLxtqSvl08vRv1lfXn_sjwBQQgFrS63ABLaU_u-ks6tt6ccFd0dRE-mz5OMQ_0ulf1iLdE8qvZIxWsaP8-BluBDjQTdN76nTRjFwWj9wmBs_yZK8Y0NuVoZVQ7uKC44oBdHPbbJ7YUNnWUtBVEFTQVRBU0ZRRTY1ZHI4SjhyVU1RcG9NcXJlZUlfS3RZUFR4UQ0163',
  //     // viewId: "DOCS",
  //     showUploadView: true,
  //     showUploadFolders: true,
  //     supportDrives: true,
  //     multiselect: false,
  //     customScopes: ['https://www.googleapis.com/auth/drive']
  //   });
  // };

  // const render = !data ? (
  //   <>
  //     <button disabled={disabled} onClick={() => handleOpenPicker()}>
  //       Open Picker
  //     </button>
  //     <p className="input__help">{helperText}</p>
  //   </>
  // ) : (
  //   <div>
  //     <p>{data?.docs[0]?.name}</p>
  //     <button onClick={handleOpenPicker}>Reset</button>
  //   </div>
  // );

  // return render;
};
