import React from 'react';
import { useAsyncDebounce } from 'react-table';

import './index.scss';

export default ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="filter">
      <ion-icon name="search-outline"></ion-icon>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Search..."
      />
    </div>
  );
};
