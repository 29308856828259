import React from "react";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import country from "../../../constants/country";

import "./index.scss";

export default ({ errors, formData, onChange, onErrorChange }) => {
  const handleInputChange = (event) => {
    onChange({
      ...formData,
      basic: {
        ...formData.basic,
        [event.target.name]: event.target.value,
      },
    });
    onErrorChange({
      ...errors,
      [event.target.name]: "",
    });
  };

  return (
    <form noValidate>
      <div className="company-add__form__inputs">
        <div className="form-control">
          <label className="labelText">
            Fund Name
            <p className="error">*</p>
          </label>
          <Input
            type="text"
            name="name"
            onChange={handleInputChange}
            value={formData.basic.name}
            error={errors.name}
          />
        </div>
        {/* <div className="form-control">
          <label className="labelText">
            Investor Name
            <p className="error">*</p>
          </label>
          <Input
            type="textarea"
            name="ownerName"
            onChange={handleInputChange}
            value={formData.basic.ownerName}
            error={errors.ownerName}
          />
        </div> */}
        {/* <div className="form-control">
          <label className="labelText">
            Partner Name
            <p className="error">*</p>
          </label>
          <Input
            type="textarea"
            name="partnerEmail"
            onChange={handleInputChange}
            value={formData.basic.partnerEmail}
            error={errors.partnerEmail}
          />
        </div> */}
        <div className="form-control">
          <label className="labelText">Fund Dashboard URL</label>
          <Input
            type="text"
            name="googleDataStudioURL"
            onChange={handleInputChange}
            value={formData.basic.googleDataStudioURL}
            error={errors.googleDataStudioURL}
          />
        </div>
        {/* <div className="form-control">
          <label className="labelText">Investor Dashboard URL</label>
          <Input
            type="text"
            name="googleDataStudioURL"
            onChange={handleInputChange}
            value={formData.basic.googleDataStudioURL}
            error={errors.googleDataStudioURL}
          />
        </div> */}
        <div className="form-control">
          <label className="labelText">Country</label>
          <Select
            options={country}
            name="country"
            onChange={handleInputChange}
            value={formData.basic.country}
            error={errors.country}
          />
        </div>
      </div>
    </form>
  );
};
