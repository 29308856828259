import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTable, useGlobalFilter, useFilters } from "react-table";
import format from "date-fns/format";

import Button from "./../../../components/Button";
import PageHeader from "../../../components/PageHeader";
import GlobalFilter from "../../../components/GlobalFilter";
import BlankState from "../../../components/BlankState";

import { getFundsList, sendReminder, resetReminderStatus } from "./../actions";
import Company from "./../../../assets/images/company.svg";

import "./index.scss";
import { deleteFund } from "../../fundsAdd/actions";
import { Dropdown } from "react-bootstrap";
import "react-dropdown/style.css";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const funds = useSelector((state) => state.funds);
  console.log("funds: ", funds);
  const data = React.useMemo(() => funds.list, [funds.list]);

  useEffect(() => {
    dispatch(getFundsList());
  }, [dispatch]);

  useEffect(() => {
    if (!funds.isSendingReminder && funds.isSendingReminderSuccess) {
      alert("Reminder is sent successfully");
      dispatch(resetReminderStatus());
    } else if (!funds.isSendingReminder && funds.isSendingReminderFail) {
      alert("Failed to send reminder");
      dispatch(resetReminderStatus());
    }
  }, [
    funds.isSendingReminder,
    funds.isSendingReminderSuccess,
    funds.isSendingReminderFail,
    dispatch,
  ]);

  const handleSendEmail = (fundId, eventKey) => {
    console.log("handling send email");
    console.log(eventKey);
    dispatch(sendReminder(fundId, eventKey));
  };

  const handleRowEdit = (fundId) => (event) => {
    event.preventDefault();

    history.push(`/funds/edit/${fundId}`);
  };

  const handleRowDelete = (fundId) => (event) => {
    event.preventDefault();

    if (window.confirm("Are you sure you wish to delete this fund?")) {
      dispatch(deleteFund(fundId));
    }
  };

  // const handleSendEmail = (fundId) => (event) => {
  //   event.preventDefault();
  //   let ccPartner = false;

  //   if (window.confirm("Do you want to cc to company partner?")) {
  //     ccPartner = true;
  //   }

  //   if (window.confirm("Do you want to proceed with sending reminder email?")) {
  //     dispatch(sendReminder(fundId, ccPartner));
  //   }
  // };

  const options = [{ value: "one", label: "One" }];

  const defaultOption = 0;

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      // {
      //   Header: 'Investor Name',
      //   accessor: 'ownerName',
      //   Cell: ({ value }) => (value ? <>{value}</> : '--'),
      // },
      // {
      //   Header: 'Partner Name',
      //   accessor: 'partnerEmail',
      //   Cell: ({ value }) => (value ? <>{value}</> : '--'),
      // },
      {
        Header: "Last metric date",
        accessor: "lastMetricDate",
        Cell: ({ value }) => {
          return value ? (
            <>{format(Date.parse(value), "MMM do, yyyy hh:mm aa")}</>
          ) : (
            "--"
          );
        },
      },
      {
        Header: "Last reminder date",
        accessor: "lastReminderDate",
        Cell: ({ value }) => {
          return value ? (
            <>{format(Date.parse(value), "MMM do, yyyy hh:mm aa")}</>
          ) : (
            "--"
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ value }) => (
          <div className="company__table__actions">
            <Button
              variant="text-link"
              size="xs"
              onClick={handleRowEdit(value)}
              className="company__table__edit"
            >
              <ion-icon name="pencil"></ion-icon>
            </Button>
            <Button
              variant="text-link"
              size="xs"
              onClick={handleRowDelete(value)}
              className="company__table__edit"
            >
              <ion-icon name="trash-outline"></ion-icon>
            </Button>
            <Dropdown onSelect={(eventKey) => handleSendEmail(value, eventKey)}>
              <Dropdown.Toggle
                variant="ghost"
                id="dropdown-basic"
                style={{ color: "#cbcccb" }}
              >
                <ion-icon name="mail-outline"></ion-icon>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="ccn">
                  Capital call notices
                </Dropdown.Item>
                <Dropdown.Item eventKey="qpcs">
                  Quarterly partners capital statement
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter
  );

  return (
    <div className="company">
      <PageHeader
        icon={<ion-icon name="business-outline"></ion-icon>}
        title="Funds"
        subtitle="All registered funds"
      >
        <Link to="/funds/add">
          <Button type="button">Add Fund</Button>
        </Link>
      </PageHeader>
      {funds && funds.list && funds.list.length ? (
        <div className="company__table">
          <div className="company__table__filter">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>

          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <BlankState
          text="No funds available"
          icon={<img src={Company} alt="no comapny" />}
        />
      )}
    </div>
  );
};
