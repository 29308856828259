export default (
  state = {
    isForgotPasswordSuccess: false,
    isForgotPasswordFail: false,
  },
  action
) => {
  switch (action.type) {
    case 'FORGOT_PASSWORD_START':
      return {
        ...state,
        isForgotPasswordSuccess: false,
        isForgotPasswordFail: false,
      };

    case 'FORGOT_PASSWORD_SUCCESS':
      return {
        ...state,
        isForgotPasswordSuccess: true,
        data: action.data,
      };

    case 'FORGOT_PASSWORD_FAIL':
      return {
        ...state,
        isForgotPasswordFail: true,
        message:
          action.payload.status === 429
            ? action.payload.data.message
            : 'Failed to reset your password',
      };

    case 'RESET_FORGOT_PASSWORD_STATUS':
      return {
        ...state,
        isForgotPasswordSuccess: false,
        isForgotPasswordFail: false,
      };

    default:
      return state;
  }
};
