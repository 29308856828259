import React, { useState } from 'react';
import update from 'immutability-helper';

import Input from './../Input';
import Button from './../Button';
import BlankState from './../BlankState';

import FieldCard from './fieldCard';

import TemplateFormInputs from './form';

import './index.scss';

const getFieldById = (list, id) => {
  return list.find((item, index) => index === id);
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default ({
  fields,
  formName,
  onFieldChange,
  onNameChange,
  onSubmit,
  actionText = 'Save',
  allowNameEdit,
  headerLink,
  isFormEdit,
  onOrderChange,
  showAction = true,
}) => {
  const [errors, setErrors] = useState({});
  const [editState, setEditState] = useState({
    status: false,
    id: undefined,
  });
  const [formData, setFormData] = useState({
    key: '',
    name: '',
    type: '',
    isRequired: false,
    description: '',
    options: '',
    isMulti: false,
    pastDataKey: '',
    pastDataType: '',
    pastDataCurrency: '',
    pastDataHintText: '',
  });

  const validate = (values) => {
    let err = {};
    if (!values.key) {
      err.key = '*Please enter key';
    }

    if (!values.name) {
      err.name = '*Please enter field name';
    }

    if (!values.type) {
      err.type = '*Please enter type';
    }

    if (values.type === 'dropdown' && !values.options) {
      err.options = '*Please enter options';
    }

    if (values.type === 'amount' && !values.currency) {
      err.currency = '*Please select currency';
    }

    if (values.pastDataKey && !values.pastDataHintText) {
      err.pastDataHintText = '*Please enter past data label';
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const err = validate(formData);
    setErrors(err);

    if (!Object.keys(err).length) {
      if (editState && editState.status) {
        const newFields = [...fields];
        newFields[editState.id] = formData;
        onFieldChange(newFields);
      } else {
        onFieldChange([...fields, formData]);
      }

      setFormData({
        key: '',
        name: '',
        type: '',
        description: '',
        isRequired: false,
        options: '',
        isMulti: false,
        pastDataKey: '',
        pastDataHintText: '',
        pastDataType: '',
        pastDataCurrency: '',
      });

      setEditState({
        status: false,
        id: undefined,
      });
    }

    return false;
  };

  const handleTemplateChange = (event) => {
    onNameChange(event.target.value);
    setErrors({
      formName: '',
    });
  };

  const handleInputChange = (event) => {
    console.log('event: ', event.target);
    if (event.target.type === 'checkbox') {
      setFormData({
        ...formData,
        [event.target.name]: !!event.target.checked,
      });
    } else if (event.target.type === 'pastKey') {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
        pastDataType: event.target.raw.type,
        pastDataCurrency: event.target.raw.currency
          ? event.target.raw.currency
          : '',
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
    setErrors({
      ...errors,
      [event.target.name]: '',
    });
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    setEditState({ status: true, id });
    setFormData(getFieldById(fields, id));
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    setEditState({ status: false, id: {} });
    onFieldChange(update(fields, { $splice: [[id, 1]] }));
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData({
      key: '',
      name: '',
      type: '',
      description: '',
      options: '',
      isMulti: false,
      isRequired: false,
      pastDataKey: '',
      pastDataHintText: '',
    });

    setEditState({
      status: false,
      id: undefined,
    });
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      fields,
      result.source.index,
      result.destination.index
    );

    onOrderChange(items);
  };

  return (
    <div className="form-builder">
      <div className="form-builder__container">
        {allowNameEdit ? (
          <div className="form-builder__container__row">
            <div className="form-builder__container__inputs">
              <div className="form-control">
                <label className="labelText">Name</label>
                <Input
                  type="text"
                  name="formName"
                  onChange={handleTemplateChange}
                  value={formName}
                  error={errors.formName}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="form-builder__container__row">
          <div className="form-builder__container__fields">
            <div className="form-builder__container__fields__header form-builder-header">
              <div className="form-builder-header__text">
                <h1 className="title">{formName || 'New Form'}</h1>
                <label>You can start adding fields with Input Creator.</label>
              </div>
              <div className="form-builder-header__actions">{headerLink}</div>
            </div>

            {fields && fields.length ? (
              <FieldCard
                fields={fields}
                handleEdit={handleEdit}
                onDragEnd={onDragEnd}
                handleDelete={handleDelete}
              />
            ) : (
              <BlankState
                text="No fields available"
                icon={<ion-icon name="newspaper"></ion-icon>}
              />
            )}
            <label className="captionTextError">{errors.field}</label>
          </div>
          <div className="form-builder__container__inputs">
            <TemplateFormInputs
              handleSubmit={handleSubmit}
              handleInputChange={handleInputChange}
              formData={formData}
              errors={errors}
              isEditMode={editState.status}
              handleReset={handleReset}
              isFormEdit={isFormEdit}
              pastDataFields={fields}
            />
          </div>
        </div>
        {fields && fields.length && showAction ? (
          <div className="form-builder__container__submit">
            <Button
              type="submit"
              size="lg"
              onClick={() => {
                if (!formName) {
                  setErrors({
                    ...errors,
                    formName: '* Please enter form name',
                  });
                } else {
                  onSubmit();
                }
              }}
            >
              {actionText}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
