import axios from "axios";
import config from "../../../config";
import uploadFile from "../../../services/uploadFile";

const postMetricsStart = () => {
  return {
    type: "POST_METRICS_START",
  };
};

const postMetricsSuccess = ({ data }) => {
  return {
    type: "POST_METRICS_SUCCESS",
    data,
  };
};

const postMetricsFail = () => {
  return {
    type: "POST_METRICS_FAIL",
  };
};

const morphData = async (data, token) => {
  const newData = data;
  const topLevelKeys = Object.keys(newData);
  for (const key of topLevelKeys) {
    const nextLevelKeys = Object.keys(newData[key]);
    for (const itemKey of nextLevelKeys) {
      const item = newData[key][itemKey];
      if (typeof item === "object") {
        const resp = await uploadFile({ file: item, token });
        newData[key][itemKey] = resp.url;
      }
    }
  }
  return newData;
};

export function postMetrics(companyId, data) {
  return async (dispatch, getState) => {
    dispatch(postMetricsStart());

    const { login } = getState();

    try {
      const morphDataObject = await morphData(data, login.token);
      const response = await axios.post(
        `${config.API_URL}/metrics/add/${companyId}`,
        morphDataObject,
        {
          headers: {
            Authorization: `Bearer ${login.token}`,
          },
        }
      );
      dispatch(postMetricsSuccess({ data: response.data }));
    } catch (error) {
      console.log("Failed to post company", error);
      dispatch(postMetricsFail());
    }
  };
}

export const resetMetricsStatus = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_METRICS_STATUS", payload: false });
  };
};

const getPastMetricStart = () => {
  return {
    type: "GET_PAST_METRIC_START",
  };
};

const getPastMetricSuccess = ({ data }) => {
  return {
    type: "GET_PAST_METRIC_SUCCESS",
    data,
  };
};

const getPastMetricFail = () => {
  return {
    type: "GET_PAST_METRIC_FAIL",
  };
};

export function getPastMetric(companyId) {
  return (dispatch, getState) => {
    dispatch(getPastMetricStart());

    const { login } = getState();

    axios
      .get(`${config.API_URL}/metrics/read/${companyId}/last`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(getPastMetricSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log("Failed to get past metrics", ex);
        dispatch(getPastMetricFail());
      });
  };
}
