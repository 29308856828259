import React, { useState, useRef, useEffect, Fragment } from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { usePopper } from "react-popper";
import { useSelector, useDispatch } from "react-redux";
import { useGoogleLogout } from "react-google-login";
import "react-accessible-accordion/dist/fancy-example.css";

import Select from "./../../../components/Select";

import Logo from "./../../auth/images/logo.svg";

import config from "../../../config";

import { userLogout } from "./../../login/actions";
import {
  setCurrentCompanyById,
  setCurrentFundById,
} from "./../../layout/actions";

import "./index.scss";
import AccordionMenu from "../../../components/AccordionMenu";

export default ({ visibility }) => {
  const referenceRef = useRef(null);
  const popperRef = useRef(null);
  const login = useSelector((state) => state.login);
  const [items, setItems] = useState([]);
  const [visible, setVisibility] = useState(false);
  const dispatch = useDispatch();
  const { signOut, loaded } = useGoogleLogout({
    clientId:
      "138212150272-cjqbu6993kbsg1m9qr9762c0aa15j807.apps.googleusercontent.com",
    cookiePolicy: "single_host_origin",
  });

  const user = login.user;
  const scopes = user.scopes || [];
  const companyId = localStorage.getItem(config.LOCAL_STORAGE_COMPANY_KEY);
  const fundId = localStorage.getItem(config.LOCAL_STORAGE_FUND_KEY);

  useEffect(() => {
    // listen for clicks and close dropdown on body
    document.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    setItems(getItems());
  }, [user]);

  const { styles, attributes } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: "top-end",
      modifiers: [
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [0, 0],
          },
        },
      ],
    }
  );

  const getItems = () => {
    const funds = {
      uuid: "abc",
      heading: "Funds",
      content: (
        <ul>
          {scopes.includes("read:fund") ? (
            <li>
              <NavLink
                activeClassName="sidebar__list__item--active"
                to="/funds-dashboard"
              >
                <ion-icon name="home-outline"></ion-icon>
                Fund Dashboard
              </NavLink>
            </li>
          ) : null}
          {scopes.includes("read:user") ? (
            <li>
              <NavLink
                activeClassName="sidebar__list__item--active"
                to="/funds"
              >
                <ion-icon name="analytics-outline"></ion-icon>
                Funds
              </NavLink>
            </li>
          ) : null}
          {scopes.includes("read:user") || scopes.includes("read:lp_admin") ? (
            <li>
              <NavLink
                activeClassName="sidebar__list__item--active"
                to="/lp-dashboard"
              >
                <ion-icon name="document-text-outline"></ion-icon>
                LP Dashboard
              </NavLink>
            </li>
          ) : null}

          {scopes.includes("write:fund_metrics") ? (
            <li>
              <NavLink
                activeClassName="sidebar__list__item--active"
                to="funds-fill-metric"
              >
                <ion-icon name="add-circle-outline"></ion-icon>
                Enter Fund Metrics
              </NavLink>
            </li>
          ) : null}

          {scopes.includes("read:fund_metrics") ? (
            <li>
              <NavLink
                activeClassName="sidebar__list__item--active"
                to="/funds-metrics"
              >
                <ion-icon name="bar-chart-outline"></ion-icon>View Fund Metrics
              </NavLink>
            </li>
          ) : null}
        </ul>
      ),
    };

    const companies = {
      uuid: "bcd",
      heading: "Companies",
      content: (
        <ul>
          {scopes.includes("read:user") ? (
            <li>
              <NavLink
                activeClassName="sidebar__list__item--active"
                to="/dashboard"
              >
                <ion-icon name="home-outline"></ion-icon>
                Portfolio Dashboard
              </NavLink>
            </li>
          ) : null}

          {scopes.includes("write:metrics") ? (
            <li>
              <NavLink
                activeClassName="sidebar__list__item--active"
                to="/fill-metric"
              >
                <ion-icon name="add-circle-outline"></ion-icon>
                Enter Metrics
              </NavLink>
            </li>
          ) : null}
          {scopes.includes("read:metrics") ? (
            <li>
              <NavLink
                activeClassName="sidebar__list__item--active"
                to="/metrics"
              >
                <ion-icon name="bar-chart-outline"></ion-icon>View Metrics
              </NavLink>
            </li>
          ) : null}
          {scopes.includes("read:company") && true ? (
            <li>
              <NavLink
                activeClassName="sidebar__list__item--active"
                to="/companies"
              >
                <ion-icon name="business-outline"></ion-icon>Companies
              </NavLink>
            </li>
          ) : null}
        </ul>
      ),
    };

    console.log(user);

    if (user.role === "lp_admin") return [funds];
    if (user.role === "company_admin" || user.role === "company_user")
      return [companies];

    return [funds, companies];
  };

  const handleCompanyChange = (event) => {
    const companyId = event.target.value;
    dispatch(setCurrentCompanyById(companyId));
  };

  const handleFundChange = (event) => {
    const fundId = event.target.value;
    dispatch(setCurrentFundById(fundId));
  };

  const handleDocumentClick = (event) => {
    if (referenceRef.current.contains(event.target)) {
      return;
    }

    setVisibility(false);
  };
  const handleDropdownClick = (event) => {
    setVisibility(!visible);
  };

  const handleLogoutClick = (event) => {
    event.preventDefault();
    setVisibility(false);

    if (loaded) {
      signOut();
    }

    dispatch(userLogout());
  };

  const userInfoComponent = (
    <Fragment>
      <div className="profile__info">
        <p className="profile__info__name">{user.name}</p>
        <p className="profile__info__email">{user.email}</p>
      </div>
      {user.name ? <div className="profile__icon">{user.name[0]}</div> : null}
    </Fragment>
  );

  return (
    <div
      className={classnames("sidebar", {
        open: visibility,
      })}
    >
      <div className="sidebar__header">
        <img src={Logo} width={120} alt="logo" />
      </div>
      <div className="sidebar__list">
        <AccordionMenu items={items} />

        <ul>
          {scopes.includes("read:user") && true ? (
            <li>
              <NavLink
                activeClassName="sidebar__list__item--active"
                to="/users"
              >
                <ion-icon name="people-outline"></ion-icon>Users
              </NavLink>
            </li>
          ) : null}

          {scopes.includes("read:template") && true ? (
            <li>
              <NavLink
                activeClassName="sidebar__list__item--active"
                to="/templates"
              >
                <ion-icon name="albums-outline"></ion-icon>Templates
              </NavLink>
            </li>
          ) : null}
        </ul>
      </div>

      {scopes.includes("read:metrics") ? (
        <div className="sidebar__select">
          {user.companies && user.companies.length === 1 ? (
            <>
              <label className="labelText">Your Company</label>
              <p className="sidebar__select__text">{user.companies[0].name}</p>
            </>
          ) : (
            <>
              <label className="labelText">Select Company</label>
              <Select
                options={user.companies || []}
                name="companies"
                onChange={handleCompanyChange}
                value={companyId}
                menuPlacement="top"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                classNamePrefix="company-select"
                // error={errors.type}
              />
            </>
          )}
        </div>
      ) : null}
      <div className="sidebar__select">
        {user.funds && scopes.includes("read:fund_metrics") && (
          <>
            <label className="labelText">Select Fund</label>
            <Select
              options={user.funds || []}
              name="funds"
              onChange={handleFundChange}
              value={fundId}
              menuPlacement="top"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              classNamePrefix="company-select"
            />
          </>
        )}
      </div>

      <div ref={referenceRef}>
        {/* eslint-disable-next-line */}
        <a className="sidebar__profile profile" onClick={handleDropdownClick}>
          {userInfoComponent}
        </a>

        <div
          ref={popperRef}
          style={{ ...styles.popper, zIndex: 100 }}
          {...attributes.popper}
        >
          <div
            className="profilePopper"
            style={{ ...styles.offset, display: visible ? "flex" : "none" }}
          >
            <div className="profile profilePopper__profile">
              {userInfoComponent}
            </div>
            <div className="profilePopper__item">
              <NavLink
                to="/change-password"
                onClick={() => setVisibility(false)}
              >
                Change Password
              </NavLink>
            </div>
            <div className="profilePopper__item">
              {/* eslint-disable-next-line */}
              <a href="#" onClick={handleLogoutClick}>
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
