import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from './loader.json';
import * as animationDataWhite from './loader-white.json';

export default ({ variant, height = 50, width = 100 }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:
      variant === 'button' ? animationDataWhite.default : animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <Lottie options={defaultOptions} height={height} width={width} />;
};
