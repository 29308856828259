export default (
  state = {
    company: {},
    selectedCompany: {},
    isPostCompanySuccess: false,
    isPostCompanyFail: false,
    updatedCompany: {},
    deletedCompany: {},
    isUpdatingCompany: false,
    isPostingCompany: false,
    isUpdatingCompanyFail: false,
    isUpdatingCompanySuccess: false,
    isDeleteingCompanySuccess: false,
    isDeleteingCompanyFail: false,
  },
  action
) => {
  switch (action.type) {
    case 'POST_COMPANY_START':
      return {
        ...state,
        isPostingCompany: true,
        isPostCompanySuccess: false,
        isPostCompanyFail: false,
      };

    case 'POST_COMPANY_SUCCESS':
      return {
        ...state,
        isPostingCompany: false,
        isPostCompanySuccess: true,
        company: action.data,
        selectedCompany: {},
      };

    case 'POST_COMPANY_FAIL':
      return { ...state, isPostCompanyFail: true, isPostingCompany: false };

    case 'RESET_POST_COMPANY_STATUS':
      return {
        ...state,
        isPostCompanySuccess: false,
        isPostCompanyFail: false,
        isUpdatingCompanyFail: false,
        isUpdatingCompanySuccess: false,
        isDeleteingCompanySuccess: false,
        isDeleteingCompanyFail: false,
      };

    case 'GET_COMPANY_BY_ID_LOADING':
      return {
        ...state,
        isFetchingCompanyByID: true,
      };

    case 'GET_COMPANY_BY_ID':
      return {
        ...state,
        selectedCompany: !action.error ? action.payload : {},
        isFetchingCompanyByID: false,
      };

    case 'UPDATE_COMPANY_LOADING':
      return {
        ...state,
        isUpdatingCompany: true,
        isUpdatingCompanySuccess: false,
        isUpdatingCompanyFail: false,
      };

    case 'UPDATE_COMPANY':
      return {
        ...state,
        updatedCompany: !action.error ? action.payload : {},
        isUpdatingCompany: false,
        isUpdatingCompanySuccess: !action.error ? true : false,
        isUpdatingCompanyFail: action.error ? true : false,
      };

    case 'DELETE_COMPANY_LOADING':
      return {
        ...state,
        isDeleteingCompany: true,
        isDeleteingCompanySuccess: false,
        isDeleteingCompanyFail: false,
      };

    case 'DELETE_COMPANY':
      return {
        ...state,
        deletedCompany: !action.error ? action.payload : {},
        isDeleteingCompany: false,
        isDeleteingCompanySuccess: !action.error ? true : false,
        isDeleteingCompanyFail: action.error ? true : false,
      };

    default:
      return state;
  }
};
