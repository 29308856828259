import axios from 'axios';
import config from '../../../config';

const resetPasswordStart = () => {
  return {
    type: 'RESET_PASSWORD_START',
  };
};

const resetPasswordSuccess = ({ token }) => {
  return {
    type: 'RESET_PASSWORD_SUCCESS',
  };
};

const resetPasswordFail = (data) => {
  return {
    type: 'RESET_PASSWORD_FAIL',
    payload: data,
  };
};

export const resetPassword = (data, token) => {
  return (dispatch) => {
    dispatch(resetPasswordStart());
    axios
      .post(`${config.API_URL}/user/resetpassword`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        dispatch(resetPasswordSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to reset password', ex);
        dispatch(resetPasswordFail(ex.response));
      });
  };
};

export const resetPasswordStatus = () => {
  return (dispatch) => {
    dispatch({ type: 'RESET_PASSWORD_STATUS', payload: false });
  };
};
