import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PageHeader from '../../../components/PageHeader';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import StatusScreen from '../../../components/StatusScreen';

import { changePassword } from './../actions';

import './index.scss';

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const changePasswordData = useSelector((state) => state.changePassword || {});

  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [status, setStatus] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusActions, setStatusActions] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (changePasswordData.changePasswordSuccess) {
      setStatus(true);
      setStatusType('success');
      setStatusMessage('Password is successfully changed');
      setStatusActions([
        <Button
          type="button"
          size="lg"
          onClick={() => {
            setStatus(false);
            history.push('/fill-metric');
          }}
        >
          Go to Dashbord
        </Button>,
      ]);
    } else if (changePasswordData.changePasswordFail) {
      setStatus(true);
      setStatusType('error');
      setStatusMessage('Failed to change password');
      setStatusActions([
        <Button
          type="button"
          size="lg"
          onClick={() => {
            setStatus(false);
          }}
        >
          Back
        </Button>,
      ]);
    }
  }, [history, changePasswordData]);

  const validate = (values) => {
    let err = {};
    if (!values.oldPassword) {
      err.oldPassword = 'Please enter old password';
    }

    if (!values.newPassword) {
      err.newPassword = 'Please enter new password';
    }

    if (
      !values.confirmNewPassword ||
      values.newPassword !== values.confirmNewPassword
    ) {
      err.confirmNewPassword = `The two password that you've provided do not match`;
    }

    return err;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const err = validate(formData);
    setErrors(err);

    if (!Object.keys(err).length) {
      let postData = { ...formData };
      delete postData.confirmNewPassword;
      dispatch(changePassword(postData));
    }

    return false;
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setErrors({
      ...errors,
      [event.target.name]: '',
    });
  };

  return (
    <div className="change-password">
      {status ? (
        <StatusScreen
          message={statusMessage}
          type={statusType}
          actions={statusActions}
        />
      ) : (
        <>
          <PageHeader
            icon={<ion-icon name="lock-closed-outline"></ion-icon>}
            title="Change Password"
            subtitle="Change your password and recover your current one"
          />
          <div className="change-password__form">
            <form onSubmit={handleFormSubmit} noValidate>
              <div className="change-password__form__inputs">
                <div className="form-control">
                  <label className="labelText">Old Password</label>
                  <Input
                    type="password"
                    name="oldPassword"
                    onChange={handleInputChange}
                    value={formData.oldPassword}
                    error={errors.oldPassword}
                  />
                </div>
                <div className="form-control">
                  <label className="labelText">New Password</label>
                  <Input
                    type="password"
                    name="newPassword"
                    onChange={handleInputChange}
                    value={formData.newPassword}
                    error={errors.newPassword}
                  />
                </div>
                <div className="form-control">
                  <label className="labelText">Confirm New Password</label>
                  <Input
                    type="password"
                    name="confirmNewPassword"
                    onChange={handleInputChange}
                    value={formData.confirmNewPassword}
                    error={errors.confirmNewPassword}
                  />
                </div>
              </div>
              <div className="change-password__form__action">
                <Button type="submit" size="lg">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
