import React from 'react';
import cx from 'classnames';

import './index.scss';

export default ({ children, open, title, actions = [] }) => {
  return (
    <div
      className={cx('modal', {
        open: open,
      })}
    >
      <div className="modal__container">
        <div className="modal__container__header">{title || ''}</div>
        <div className="modal__container__content">{children}</div>
        {actions && actions.length ? (
          <div className="modal__container__actions">
            {actions}
          </div>
        ) : null}
      </div>
    </div>
  );
};
