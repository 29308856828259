export default (
  state = {
    list: [],
    isCopiedTemplateBYId: false,
  },
  action
) => {
  switch (action.type) {
    case 'GET_TEMPLATES_START':
      return { ...state, isFetchingTemplatessList: true };

    case 'GET_TEMPLATES_SUCCESS':
      return {
        ...state,
        isFetchingTemplatessList: false,
        list: action.data,
      };

    case 'GET_TEMPLATES_FAIL':
      return { ...state, isFetchingTemplatessList: false, list: [] };

    case 'COPY_TEMPLATE':
      return {
        ...state,
        isCopiedTemplateBYId: true,
        copiedTemplateId: action.payload,
      };

    case 'POST_TEMPLATE_START':
      return {
        ...state,
        isCopiedTemplateBYId: false,
        copiedTemplateId: '',
      };

    case 'POST_TEMPLATE_SUCCESS':
      return {
        ...state,
        isCopiedTemplateBYId: false,
        copiedTemplateId: '',
      };

    default:
      return state;
  }
};
