import axios from 'axios';
import config from '../../../config';

import { getUsersList } from './../../users/actions';

const postUserStart = () => {
  return {
    type: 'POST_USER_START',
  };
};

const postUserSuccess = ({ data }) => {
  return {
    type: 'POST_USER_SUCCESS',
    data,
  };
};

const postUserFail = () => {
  return {
    type: 'POST_USER_FAIL',
  };
};

export function postUser(data) {
  return (dispatch, getState) => {
    dispatch(postUserStart());

    const { login } = getState();
    axios
      .post(`${config.API_URL}/user/create`, data, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(postUserSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to post user', ex);
        dispatch(postUserFail());
      });
  };
}

export const resetPostUserStatus = () => {
  return (dispatch) => {
    dispatch({ type: 'RESET_POST_USER_STATUS', payload: false });
  };
};

// Fetch information of logged in user
export const getUserRole = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_USER_ROLES_LOADING',
      payload: true,
    });

    const { login } = getState();
    axios
      .get(`${config.API_URL}/user/roles`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: 'GET_USER_ROLES',
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log('Failed to fetch user roles', ex);
        dispatch({
          type: 'GET_USER_ROLES',
          payload: ex,
          error: true,
        });
      });
  };
};

// Fetch user by Id
export const getUserById = (userId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_USER_BY_ID_LOADING',
      payload: true,
    });

    const { login } = getState();
    axios
      .get(`${config.API_URL}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: 'GET_USER_BY_ID',
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log('Failed to fetch user', ex);
        dispatch({
          type: 'GET_USER_BY_ID',
          payload: ex,
          error: true,
        });
      });
  };
};

// Update user by Id
export const updateUser = (userId, data) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_USER_LOADING',
      payload: true,
    });

    const { login } = getState();
    axios
      .put(`${config.API_URL}/user/${userId}`, data, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: 'UPDATE_USER',
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log('Failed to update user', ex);
        dispatch({
          type: 'UPDATE_USER',
          payload: ex,
          error: true,
        });
      });
  };
};

// Delete user by Id
export const deleteUser = (userId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'DELETE_USER_LOADING',
      payload: true,
    });

    const { login } = getState();
    axios
      .delete(`${config.API_URL}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: 'DELETE_USER',
          payload: resp.data,
        });

        dispatch(getUsersList());
      })
      .catch((ex) => {
        console.log('Failed to delete user', ex);
        dispatch({
          type: 'UPDATE_USER',
          payload: ex,
          error: true,
        });
      });
  };
};
