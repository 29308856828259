import React from 'react';
import Select from 'react-select';

import './index.scss';

export default ({
  onChange,
  name,
  value,
  options = [],
  getOptionLabel = (record) => record.label,
  getOptionValue = (record) => record.value,
  error,
  helperText = '',
  pastData = '',
  ...rest
}) => {
  const handleChange = (record) => {
    onChange({
      target: {
        name,
        value: Array.isArray(record)
          ? record.map(getOptionValue)
          : getOptionValue(record),
        raw: record,
      },
    });
  };

  let selectedRecord;
  if (Array.isArray(value)) {
    const optionByValue = {};
    for (let i = 0, l = options.length; i < l; i += 1) {
      optionByValue[getOptionValue(options[i])] = options[i];
    }
    selectedRecord = value.map((t) => optionByValue[t]);
  } else {
    selectedRecord =
      options.find((record) => getOptionValue(record) === value) || '';
  }

  return (
    <>
      <Select
        {...rest}
        options={options}
        value={selectedRecord}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={handleChange}
        className="select"
        theme={(theme) => ({
          ...theme,
          outline: 'none',
          colors: {
            ...theme.colors,
            primary25: 'rgba(255, 121, 0, 0.2)',
            primary: 'rgba(255, 121, 0, 0.7)',
            danger: 'rgba(255, 121, 0, 0.7)',
            dangerLight: 'rgba(255, 121, 0, 0.2)',
          },
        })}
      />
      {error ? (
        <p className="select__error">{error}</p>
      ) : (
        <p className="select__help">
          {helperText}
        </p>
      )}
      <p className="select__help">
        {pastData}
      </p>
    </>
  );
};
