export default (
  state = {
    isPostingMetric: false,
    isPostMetricsSuccess: false,
    isPostMetricsFail: false,
    pastData: {},
    isFetchingPastMetric: false,
  },
  action
) => {
  switch (action.type) {
    case 'POST_FUND_METRICS_START':
      return {
        ...state,
        isPostingMetric: true,
        isPostMetricsSuccess: false,
        isPostMetricsFail: false,
      };

    case 'POST_FUND_METRICS_SUCCESS':
      return {
        ...state,
        isPostingMetric: false,
        isPostMetricsSuccess: true,
        metrics: action.data,
      };

    case 'POST_FUND_METRICS_FAIL':
      return { ...state, isPostingMetric: false, isPostMetricsFail: true };

    case 'RESET_FUND_METRICS_STATUS':
      return {
        ...state,
        isPostMetricsFail: false,
        isPostMetricsSuccess: false,
      };

    case 'GET_PAST_FUND_METRIC_START':
      return { ...state, isFetchingPastMetric: true };

    case 'GET_PAST_FUND_METRIC_SUCCESS':
      const pastData = action.data;
      return {
        ...state,
        isFetchingPastMetric: false,
        pastData,
      };

    case 'GET_PAST_FUND_METRIC_FAIL':
      return { ...state, isFetchingPastMetric: false, pastData: {} };

    default:
      return state;
  }
};
