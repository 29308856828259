import axios from 'axios';
import config from '../../../config';

const forgotPasswordStart = () => {
  return {
    type: 'FORGOT_PASSWORD_START',
  };
};

const forgotPasswordSuccess = ({ token }) => {
  return {
    type: 'FORGOT_PASSWORD_SUCCESS',
  };
};

const forgotPasswordFail = (data) => {
  return {
    type: 'FORGOT_PASSWORD_FAIL',
    payload: data,
  };
};

export const forgotPassword = (data) => {
  return (dispatch) => {
    dispatch(forgotPasswordStart());
    axios
      .post(`${config.API_URL}/user/forgotpassword`, data)
      .then((resp) => {
        dispatch(forgotPasswordSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to forgot password', ex);
        dispatch(forgotPasswordFail(ex.response));
      });
  };
};

export const resetForgotPasswordStatus = () => {
  return (dispatch) => {
    dispatch({ type: 'RESET_FORGOT_PASSWORD_STATUS', payload: false });
  };
};
