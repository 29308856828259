import React from "react";
// import { useSelector, useDispatch } from 'react-redux';

import "./index.scss";

export default ({
  prefix,
  type,
  label = null,
  error,
  helperText = "",
  pastData = "",
  ...rest
}) => {
  return (
    <div className="input">
      <label>{label}</label>
      <div className="input__control">
        {type === "textarea" ? (
          <textarea {...rest}></textarea>
        ) : (
          <>
            {prefix ? <>{prefix}</> : null}
            <input
              {...rest}
              type={type}
              style={{ paddingLeft: prefix ? "33px" : "10px" }}
            ></input>
          </>
        )}
      </div>
      {error ? (
        <p className="input__error">{error}</p>
      ) : (
        <p className="input__help">{helperText}</p>
      )}
      <p className="select__help">{pastData}</p>
    </div>
  );
};
