import React from 'react';
import NumberFormat from 'react-number-format';
import Input from './../Input';
import './index.scss';

export default ({
  prefix,
  type,
  label = null,
  error,
  helperText = '',
  onChange,
  name,
  pastData = '',
  ...rest
}) => {
  return (
    <>
      <NumberFormat
        {...rest}
        onValueChange={(raw) =>
          onChange({ target: { value: raw.value, name, raw } })
        }
        prefix={prefix}
        customInput={Input}
        isNumericString={true}
      />
      {error ? (
        <p className="input__error">{error}</p>
      ) : (
        <p className="input__help">{helperText}</p>
      )}
      <p className="select__help">{pastData}</p>
    </>
  );
};
