import React from 'react';

import './index.scss';

export default ({ icon, title, children = null, subtitle}) => {
  return (
    <div className="header">
      <div className="header__title">
        {icon}
        <div className="header__title__name">
          <h1 className="title">{title}</h1>
          <p className="captionTextItalic">{subtitle}</p>
        </div>
      </div>
      <div className="action">{children}</div>
    </div>
  );
};
