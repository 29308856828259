import React from "react";
import { useSelector } from "react-redux";

import BlankState from "../../../components/BlankState";
import PageHeader from "../../../components/PageHeader";
import NoData from "./../../../assets/images/no-data.svg";

import "./index.scss";

export default () => {
  const selectedFund = useSelector((state) => state.layout.fund);
  console.log(selectedFund, "selectedFund");

  if (
    !selectedFund ||
    !selectedFund.basic ||
    !selectedFund.basic.googleDataStudioURL
  ) {
    return (
      <div className="dashboard__blank">
        <BlankState
          text="No data available"
          icon={<img src={NoData} alt="No data"></img>}
        />
      </div>
    );
  }

  return (
    <>
      <PageHeader
        icon={<ion-icon name="business-outline"></ion-icon>}
        title={selectedFund.basic.name}
        subtitle="Fund Dashboard"
      />
      <div className="dashboard">
        <iframe
          title="Dashboard"
          className="dashboard__frame"
          src={selectedFund.basic.googleDataStudioURL}
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </>
  );
};
