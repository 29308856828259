import axios from 'axios';
import config from '../../../config';

import {getCompaniesList} from './../../companies/actions';

const postCompanyStart = () => {
  return {
    type: 'POST_COMPANY_START',
  };
};

const postCompanySuccess = ({ data }) => {
  return {
    type: 'POST_COMPANY_SUCCESS',
    data,
  };
};

const postCompanyFail = () => {
  return {
    type: 'POST_COMPANY_FAIL',
  };
};

export function postCompany(data) {
  return (dispatch, getState) => {
    dispatch(postCompanyStart());

    const { login } = getState();
    axios
      .post(`${config.API_URL}/company/create`, data, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(postCompanySuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to post company', ex);
        dispatch(postCompanyFail());
      });
  };
}

export const resetPostCompanyStatus = () => {
  return (dispatch) => {
    dispatch({ type: 'RESET_POST_COMPANY_STATUS', payload: false });
  };
};

export const getCompanyById = (companyId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_COMPANY_BY_ID_LOADING',
      payload: true,
    });

    const { login } = getState();
    axios
      .get(`${config.API_URL}/company/${companyId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: 'GET_COMPANY_BY_ID',
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log('Failed to fetch company', ex);
        dispatch({
          type: 'GET_COMPANY_BY_ID',
          payload: ex,
          error: true,
        });
      });
  };
};

// update company
export const updateCompany = (companyId, data) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_COMPANY_LOADING',
      payload: true,
    });

    const { login } = getState();
    axios
      .put(`${config.API_URL}/company/${companyId}`, data, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: 'UPDATE_COMPANY',
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log('Failed to update company', ex);
        dispatch({
          type: 'UPDATE_COMPANY',
          payload: ex,
          error: true,
        });
      });
  };
};

// delete company
export const deleteCompany = (companyId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'DELETE_COMPANY_LOADING',
      payload: true,
    });

    const { login } = getState();
    axios
      .delete(`${config.API_URL}/company/${companyId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: 'DELETE_COMPANY',
          payload: resp.data,
        });

        dispatch(getCompaniesList());
      })
      .catch((ex) => {
        console.log('Failed to update company', ex);
        dispatch({
          type: 'DELETE_COMPANY',
          payload: ex,
          error: true,
        });
      });
  };
};

