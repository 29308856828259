import axios from 'axios';
import config from '../../../config';

export const setCurrentCompanyById = (companyId) => {
  return (dispatch, getState) => {
    localStorage.setItem(config.LOCAL_STORAGE_COMPANY_KEY, companyId);
    dispatch(getCurrentCompanyById(companyId));
  };
};

export const setCurrentFundById = (fundId) => {
  return (dispatch, getState) => {
    localStorage.setItem(config.LOCAL_STORAGE_FUND_KEY, fundId);
    dispatch(getCurrentFundById(fundId));
  };
};

// Fetch company by companyID
export const getCurrentCompanyById = (companyId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_CURRENT_COMPANY_BY_ID_LOADING',
      payload: true,
    });

    const { login } = getState();
    axios
      .get(`${config.API_URL}/company/${companyId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: 'GET_CURRENT_COMPANY_BY_ID',
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log('Failed to fetch company', ex);
        dispatch({
          type: 'GET_CURRENT_COMPANY_BY_ID',
          payload: ex,
          error: true,
        });
      });
  };
};

export const getCurrentFundById = (fundId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_CURRENT_FUND_BY_ID_LOADING',
      payload: true,
    });

    const { login } = getState();
    axios
      .get(`${config.API_URL}/funds/${fundId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: 'GET_CURRENT_FUND_BY_ID',
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log('Failed to fetch fund', ex);
        dispatch({
          type: 'GET_CURRENT_FUND_BY_ID',
          payload: ex,
          error: true,
        });
      });
  };
};
