import axios from 'axios';
import config from '../../../config';

const getTemplatesStart = () => {
  return {
    type: 'GET_TEMPLATES_START',
  };
};

const getTemplatesSuccess = ({ data }) => {
  return {
    type: 'GET_TEMPLATES_SUCCESS',
    data,
  };
};

const getTemplatesFail = () => {
  return {
    type: 'GET_TEMPLATES_FAIL',
  };
};

export function getTemplates() {
  return (dispatch, getState) => {
    dispatch(getTemplatesStart());

    const { login } = getState();

    axios
      .get(`${config.API_URL}/template/all`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(getTemplatesSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to post user', ex);
        dispatch(getTemplatesFail());
      });
  };
}

export const copyTemplateById = (templateId) => {
  return (dispatch) => {
    dispatch({ type: 'COPY_TEMPLATE', payload: templateId });
  };
};

export const resetCopiedTemplate = (templateId) => {
  return (dispatch) => {
    dispatch({ type: 'RESET_COPIED_TEMPLATE', payload: templateId });
  };
};
