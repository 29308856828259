export default [
  {
    label: '₹ INR',
    value: 'INR',
    symbol: '₹'
  },
  {
    label: '$ USD',
    value: 'USD',
    symbol: '$'
  },
];
