import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Button from './../../../components/Button';
import PageHeader from '../../../components/PageHeader';
import StatusScreen from '../../../components/StatusScreen';
import FormBuilder from '../../../components/FormBuilder';
import Loader from '../../../components/Loader';

import {
  postTemplate,
  resetTemplateStatus,
  getTemplateById,
  updateTemplate,
} from './../actions/index';

import { resetCopiedTemplate } from './../../templates/actions';
import './index.scss';

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const templatesData = useSelector((state) => state.templatesData);
  const copiedTemplate = useSelector(
    (state) => state.templates.copiedTemplateId
  );
  const [status, setStatus] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusActions, setStatusActions] = useState([]);
  const [isEdit, setEditState] = useState();
  const [isCopy, setCopiedState] = useState();

  const [template, setTemplate] = useState({
    name: '',
    rows: [],
  });

  useEffect(() => {
    if (params.templateId) {
      setEditState(true);
      dispatch(getTemplateById(params.templateId));
    }
  }, [params.templateId, dispatch]);

  useEffect(() => {
    if (copiedTemplate) {
      setCopiedState(true);
      dispatch(getTemplateById(copiedTemplate));
    }
  }, [copiedTemplate, dispatch]);

  useEffect(() => {
    if (
      templatesData.selectedTemplate &&
      templatesData.selectedTemplate.id &&
      isEdit
    ) {
      setTemplate({
        name: templatesData.selectedTemplate.templateName,
        rows: templatesData.selectedTemplate.templateJson,
      });
    }
  }, [templatesData.selectedTemplate, isEdit]);

  useEffect(() => {
    if (
      templatesData.selectedTemplate &&
      templatesData.selectedTemplate.id &&
      isCopy
    ) {
      setTemplate({
        name: templatesData.selectedTemplate.templateName,
        rows: templatesData.selectedTemplate.templateJson,
      });
    }
  }, [templatesData.selectedTemplate, isCopy]);

  useEffect(() => {
    if (templatesData.postTemplateSuccess && templatesData.template) {
      setStatus(true);
      setStatusType('success');
      setStatusMessage(
        `Form (${templatesData.template.templateName}) is successfully created`
      );
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
            history.push('/templates');
          }}
        >
          Not Now
        </Button>,
        <Button
          type="button"
          size="lg"
          onClick={() => {
            setStatus(false);
            setTemplate({
              name: '',
              rows: [],
            });
          }}
        >
          Add Another Form
        </Button>,
      ]);
      dispatch(resetTemplateStatus());
      dispatch(resetCopiedTemplate());
    } else if (templatesData.postTemplateFail) {
      setStatus(true);
      setStatusType('error');
      setStatusMessage(`Failed to save template`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
          }}
        >
          Back
        </Button>,
      ]);
      dispatch(resetTemplateStatus());
      dispatch(resetCopiedTemplate());
    }

    if (
      templatesData.isUpdatingTemplateSuccess &&
      templatesData.updatedTemplate
    ) {
      setStatus(true);
      setStatusType('success');
      setStatusMessage(
        `${templatesData.updatedTemplate.templateName} is successfully updated`
      );
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
            history.push('/templates');
          }}
        >
          Go to list
        </Button>,
      ]);
      dispatch(resetTemplateStatus());
    } else if (templatesData.isUpdatingTemplateFail) {
      setStatus(true);
      setStatusType('error');
      setStatusMessage(`Failed to update template`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
          }}
        >
          Back
        </Button>,
      ]);
      dispatch(resetTemplateStatus());
    }
  }, [dispatch, history, templatesData]);

  const validate = (values) => {
    let err = {};

    if (!values || !values.name || !values.rows || !values.rows.length) {
      err.template = 'Not Complete';
    }

    return err;
  };

  const handleSheetFieldChange = (data) => {
    setTemplate({
      ...template,
      rows: data,
    });
  };

  const handleSheetNameChange = (value) => {
    setTemplate({
      ...template,
      name: value,
    });
  };

  const handleTemplateSave = () => {
    const err = validate(template);

    const templateFormData = {
      templateName: template.name,
      templateJson: template.rows,
    };

    if (!Object.keys(err).length) {
      if (isEdit && params.templateId) {
        dispatch(updateTemplate(params.templateId, templateFormData));
      } else {
        dispatch(postTemplate(templateFormData));
      }
    }
  };

  if (templatesData.isFetchingTemplateByID) {
    return (
      <div className="app-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="template-form">
      <PageHeader
        icon={<ion-icon name="albums-outline"></ion-icon>}
        title="Create Templates"
        subtitle="Build your template"
      />

      {status ? (
        <StatusScreen
          message={statusMessage}
          type={statusType}
          actions={statusActions}
        />
      ) : (
        <FormBuilder
          fields={template.rows}
          formName={template.name}
          onFieldChange={handleSheetFieldChange}
          onNameChange={handleSheetNameChange}
          onSubmit={handleTemplateSave}
          actionText="Save Template"
          allowNameEdit={true}
          onOrderChange={(fields) => {
            setTemplate({
              ...template,
              rows: fields,
            });
          }}
        />
      )}
    </div>
  );
};
