import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import format from "date-fns/format";

import PageHeader from "../../../components/PageHeader";
import Input from "../../../components/Input";
import NumberInput from "../../../components/NumberInput";
import Select from "../../../components/Select";
import BlankState from "../../../components/BlankState";
import Stepper from "../../../components/Stepper";
import DatePicker from "../../../components/DatePicker";
import Button from "../../../components/Button";
import StatusScreen from "../../../components/StatusScreen";
import Loader from "../../../components/Loader";

import { postMetrics, resetMetricsStatus, getPastMetric } from "./../actions";

import currencyList from "./../../../constants/currencyType";

import IconForm from "./../../../assets/images/form.svg";

import "./index.scss";
import FileInput from "../../../components/FileInput";

const ExcelDateToJSDate = (serial) => {
  if (!serial) {
    return new Date();
  }

  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  const date_info = new Date(utc_value * 1000);
  const fractional_day = serial - Math.floor(serial) + 0.0000001;
  let total_seconds = Math.floor(86400 * fractional_day);
  const seconds = total_seconds % 60;
  total_seconds -= seconds;
  const hours = Math.floor(total_seconds / (60 * 60));
  const minutes = Math.floor(total_seconds / 60) % 60;
  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
};

const numberPattern = /^[+-]?(?=.)(?:\d+,)*\d*(?:\.\d+)?$/;
const validateSingle = (field, value) => {
  if (field.isRequired && !value) {
    return "*This field is mandatory";
  } else if (
    field.isRequired &&
    field.type === "number" &&
    !numberPattern.test(value)
  ) {
    return "*Please enter a valid number";
  }

  return null;
};

const getCurrencyIconByCurrency = (value) => {
  return currencyList.find((currency) => currency.value === value) || {};
};

const validate = (form, data) => {
  const errors = {};
  for (let i = 0, l = form.length; i < l; i += 1) {
    const message = validateSingle(form[i], data[form[i].key]);
    if (message) {
      errors[form[i].key] = message;
    }
  }

  return errors;
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);
  const [status, setStatus] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusActions, setStatusActions] = useState([]);
  const [formData, setFormData] = useState({});

  const [errors, setErrors] = useState({});

  const metricForm = useSelector((state) => state.metricForm);
  const pastData = metricForm.pastData || {};
  const layout = useSelector((state) => state.layout);
  const company = useSelector((state) => state.layout.company);
  const formSheets = company.sheets ? company.sheets : {};
  const formKeys =
    Object.keys(formSheets).sort(
      (a, b) => formSheets[a].order - formSheets[b].order
    ) || [];
  const formLabels = formKeys.map((key) => formSheets[key].name);
  const selectedSheet = formSheets[formKeys[activeIndex]] || {};
  const sheetFormData = formData[formKeys[activeIndex]] || {};
  const login = useSelector((state) => state.login);

  const fieldByKey = useMemo(() => {
    const data = {};

    if (selectedSheet.rows) {
      for (let i = 0, l = selectedSheet.rows.length; i < l; i += 1) {
        data[selectedSheet.rows[i].key] = selectedSheet.rows[i];
      }
    }

    return data;
  }, [selectedSheet.rows]);

  useEffect(() => {
    if (company && company.basic && company.basic.id) {
      dispatch(getPastMetric(company.basic.id));
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (metricForm.isPostMetricsSuccess) {
      setStatus(true);
      setStatusType("success");
      setStatusMessage(`Metric is successfully saved`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
            setFormData({});
            history.push("/metrics");
          }}
        >
          Go to list
        </Button>,
      ]);
      dispatch(resetMetricsStatus());
    } else if (metricForm.isPostMetricsFail) {
      setStatus(true);
      setStatusType("error");
      setStatusMessage(`Failed to save metric`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
          }}
        >
          Back
        </Button>,
      ]);
      dispatch(resetMetricsStatus());
    }
  }, [dispatch, history, metricForm]);

  const handleInputChange =
    (keyType, selectedFile) =>
    (
      // getValue = (e) => e.target.value,
      event
    ) => {
      setFormData({
        ...formData,
        [keyType]: {
          ...sheetFormData,
          [event.target.name]: selectedFile || event.target.value,
        },
      });
      setErrors({
        ...errors,
        [event.target.name]: "",
      });
    };

  const handleStepClick = (index) => {
    // Validate form fields
    const formType = formKeys[activeIndex];
    const err = validate(formSheets[formType].rows, sheetFormData);

    if (Object.keys(err).length) {
      setErrors(err);
    } else {
      setActiveIndex(index);
      setErrors({});
    }
  };

  const handleBack = () => {
    setActiveIndex(activeIndex - 1);

    setErrors({});
  };

  const handleNext = () => {
    // Validate form fields
    const formType = formKeys[activeIndex];
    const err = validate(formSheets[formType].rows, sheetFormData);

    if (Object.keys(err).length) {
      setErrors(err);
    } else {
      setActiveIndex(activeIndex + 1);
      setErrors({});
    }
  };

  const handleInputBlur = (keyType, field) => () => {
    const err = validateSingle(field, sheetFormData[field.key]);

    if (err) {
      setErrors({
        ...errors,
        [field.key]: err,
      });
    }
  };

  const handleSubmit = (e) => {
    // Validate form fields
    e.preventDefault();
    const formType = formKeys[activeIndex];
    const err = validate(formSheets[formType].rows, sheetFormData);

    if (Object.keys(err).length) {
      setErrors(err);
    } else {
      let requestFormData = { ...formData };

      const formTypeKeys = Object.keys(formData);

      for (
        let i = 0, formTypeKeysLen = formTypeKeys.length;
        i < formTypeKeysLen;
        i += 1
      ) {
        const typeForm = formData[formTypeKeys[i]];
        const typeFormKeys = Object.keys(formData[formTypeKeys[i]]);
        const fieldsWithPastData = formSheets[formTypeKeys[i]].rows.filter(
          (record) => record.isPast
        );

        for (
          let j = 0, fieldsLen = fieldsWithPastData.length;
          j < fieldsLen;
          j += 1
        ) {
          const pastDataKey = fieldsWithPastData[j].key.replace("past_", "");
          const pastDataValue = pastData[pastDataKey];

          requestFormData[formTypeKeys[i]] = {
            ...requestFormData[formTypeKeys[i]],
            [fieldsWithPastData[j].key]: pastDataValue,
          };
        }

        for (
          let j = 0, typeFormKeysLen = typeFormKeys.length;
          j < typeFormKeysLen;
          j += 1
        ) {
          if (
            typeForm[typeFormKeys[j]] &&
            Array.isArray(typeForm[typeFormKeys[j]])
          ) {
            typeForm[typeFormKeys[j]] = typeForm[typeFormKeys[j]].join(", ");
          }
        }
      }

      console.log(requestFormData, "requestFormData");

      dispatch(postMetrics(company.basic.id, requestFormData));
    }
  };

  const getInputByType = (data, keyType) => {
    let pastDataKey;
    let pastDataValue = "";
    const pastDataLabel = "";

    if (data.isPast) {
      pastDataKey = data.key.replace("past_", "");
      pastDataValue = pastData[pastDataKey];
    } else {
      pastDataValue = sheetFormData[data.key] || "";
    }

    switch (data.type) {
      case "text":
        return (
          <Input
            type="text"
            name={data.key}
            onChange={handleInputChange(keyType)}
            onBlur={handleInputBlur(keyType, data)}
            value={pastDataValue}
            error={errors[data.key]}
            helperText={data.description}
            pastData={pastDataLabel}
            disabled={data.isPast}
          />
        );

      case "textarea":
        return (
          <Input
            type="textarea"
            name={data.key}
            onChange={handleInputChange(keyType)}
            onBlur={handleInputBlur(keyType, data)}
            value={pastDataValue}
            error={errors[data.key]}
            helperText={data.description}
            pastData={pastDataLabel}
            disabled={data.isPast}
          />
        );
      case "number":
        return (
          <NumberInput
            name={data.key}
            value={pastDataValue}
            thousandSeparator={true}
            customInput={Input}
            onChange={handleInputChange(keyType)}
            onBlur={handleInputBlur(keyType, data)}
            error={errors[data.key]}
            helperText={data.description}
            pastData={pastDataLabel}
            disabled={data.isPast}
          />
        );

      case "date":
        return (
          <DatePicker
            name={data.key}
            onChange={handleInputChange(keyType)}
            onBlur={handleInputBlur(keyType, data)}
            value={pastDataValue}
            error={errors[data.key]}
            helperText={data.description}
            pastData={pastDataLabel}
            disabled={data.isPast}
          />
        );

      case "amount":
        return (
          <NumberInput
            name={data.key}
            value={pastDataValue}
            thousandSeparator={true}
            onChange={handleInputChange(keyType)}
            error={errors[data.key]}
            helperText={data.description || `Currency: ${data.currency}`}
            prefix={getCurrencyIconByCurrency(data.currency).symbol || ""}
            pastData={pastDataLabel}
            disabled={data.isPast}
            allowLeadingZeros={true}
            thousandsGroupStyle={data.currency === "INR" ? "lakh" : null}
          />
        );

      case "dropdown":
        return (
          <Select
            name={data.key}
            onChange={handleInputChange(keyType)}
            value={pastDataValue}
            error={errors[data.key]}
            helperText={data.description}
            options={
              data.options.split("|").map((option) => ({
                label: option,
                value: option,
              })) || []
            }
            isMulti={data.isMulti}
            pastData={pastDataLabel}
            isDisabled={data.isPast}
          />
        );

      case "month_year":
        return (
          <DatePicker
            name={data.key}
            onChange={handleInputChange(keyType)}
            onBlur={handleInputBlur(keyType, data)}
            value={pastDataValue}
            error={errors[data.key]}
            helperText={data.description}
            pastData={pastDataLabel}
            disabled={data.isPast}
            showMonthYearPicker
            showFullMonthYearPicker
          />
        );

      case "file":
        return (
          <FileInput
            name={data.key}
            token={login.token}
            helperText={data.description}
            disabled={data.isPast}
            onChange={(selectedFile, e) => {
              console.log(keyType, "keyType");
              console.log(formData, "formData");
              handleInputChange(keyType, selectedFile)(e);
            }}
          />
        );

      default:
        return (
          <Input
            type="text"
            name={data.key}
            onChange={handleInputChange(keyType)}
            onBlur={handleInputBlur(keyType, data)}
            value={pastDataValue}
            error={errors[data.key]}
            helperText={data.description}
            pastData={pastDataLabel}
            disabled={data.isPast}
          />
        );
    }
  };

  if (layout.isFetchingCompany) {
    return (
      <div className="app-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="financial-metric-form">
      {status ? (
        <StatusScreen
          message={statusMessage}
          type={statusType}
          actions={statusActions}
        />
      ) : (
        <>
          <PageHeader
            icon={<ion-icon name="newspaper-outline"></ion-icon>}
            title="Enter Metrics"
            subtitle="Fill metrics data"
          />

          {!formKeys || !formKeys.length ? (
            <BlankState
              text="No Metric Form"
              icon={<img src={IconForm} alt="No Form" />}
            />
          ) : (
            <>
              <Stepper
                list={formLabels}
                activeIndex={activeIndex}
                handleClick={handleStepClick}
              />

              <div className="financial-metric-form__form">
                <form noValidate onSubmit={(e) => e.preventDefault()}>
                  <div className="financial-metric-form__form__inputs">
                    {selectedSheet.rows &&
                      selectedSheet.rows.length &&
                      selectedSheet.rows.map((data) => {
                        let labelName = data.name;

                        if (data.isPast) {
                          const pastDataKey = data.key.replace("past_", "");

                          labelName = data.name
                            .replace(
                              "{label}",
                              fieldByKey[pastDataKey]
                                ? fieldByKey[pastDataKey].name
                                : ""
                            )
                            .replace(
                              "{reportingDate}",

                              pastData.reportingDate
                                ? format(
                                    ExcelDateToJSDate(pastData.reportingDate),
                                    "MM/dd/yyyy"
                                  )
                                : ""
                            );
                        }

                        return (
                          <div className="form-control" key={data.id}>
                            <label className="labelText">
                              {labelName}
                              <p className="error">
                                {" "}
                                {data.isRequired ? "*" : ""}
                              </p>
                            </label>
                            {getInputByType(data, formKeys[activeIndex])}
                          </div>
                        );
                      })}
                  </div>
                </form>
              </div>

              <div className="financial-metric-form__actions">
                {activeIndex > 0 ? (
                  <Button
                    variant="link"
                    type="button"
                    size="lg"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                ) : null}
                {activeIndex === formKeys.length - 1 ? (
                  <Button
                    type="submit"
                    size="lg"
                    onClick={handleSubmit}
                    disabled={metricForm.isPostingMetric}
                  >
                    {metricForm.isPostingMetric ? "Loading..." : "Submit"}
                  </Button>
                ) : (
                  <Button type="button" size="lg" onClick={handleNext}>
                    Next
                  </Button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
