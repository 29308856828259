export default (
  state = {
    isPostUserSuccess: false,
    isPostUserFail: false,
    user: {},
    isFetchingUserRoles: false,
    userRoles: [],
    isFetchingUserByID: false,
    selectedUser: {},
    updatedUser: {},
  },
  action
) => {
  switch (action.type) {
    case 'POST_USER_START':
      return {
        ...state,
        isPostingUser: true,
        isPostUserSuccess: false,
        isPostUserFail: false,
      };

    case 'POST_USER_SUCCESS':
      return {
        ...state,
        isPostingUser: false,
        isPostUserSuccess: true,
        user: action.data,
      };

    case 'POST_USER_FAIL':
      return { ...state, isPostUserFail: true, isPostingUser: false };

    case 'RESET_POST_USER_STATUS':
      return {
        ...state,
        isPostUserSuccess: false,
        isPostUserFail: false,
        isUpdatingUserSuccess: false,
        isUpdatingUserfail: false,
      };

    case 'GET_USER_ROLES_LOADING':
      return {
        ...state,
        isFetchingUserRoles: true,
      };

    case 'GET_USER_ROLES':
      return {
        ...state,
        userRoles: !action.error ? action.payload : [],
        isFetchingUserRoles: false,
      };

    case 'GET_USER_BY_ID_LOADING':
      return {
        ...state,
        isFetchingUserByID: true,
      };

    case 'GET_USER_BY_ID':
      return {
        ...state,
        selectedUser: !action.error ? action.payload : {},
        isFetchingUserByID: false,
      };

    case 'UPDATE_USER_LOADING':
      return {
        ...state,
        isUpdatingUser: true,
        isUpdatingUserSuccess: false,
        isUpdatingUserFail: false,
      };

    case 'UPDATE_USER':
      return {
        ...state,
        updatedUser: !action.error ? action.payload : {},
        isUpdatingUser: false,
        isUpdatingUserSuccess: !action.error ? true : false,
        isUpdatingUserFail: action.error ? true : false,
      };

    case 'DELETE_USER_LOADING':
      return {
        ...state,
        isDeleteingUser: true,
        isDeleteingUserSuccess: false,
        isDeleteingUserFail: false,
      };

    case 'DELETE_USER':
      return {
        ...state,
        deletedUser: !action.error ? action.payload : {},
        isDeleteingUser: false,
        isDeleteingUserSuccess: !action.error ? true : false,
        isDeleteingUserFail: action.error ? true : false,
      };

    default:
      return state;
  }
};
