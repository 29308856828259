import axios from 'axios';
import config from '../../../config';

const getFinancialMetricsStart = () => {
  return {
    type: 'GET_FUND_FINANCIAL_METRICS_START',
  };
};

const getFinancialMetricsSuccess = ({ data }) => {
  return {
    type: 'GET_FUND_FINANCIAL_METRICS_SUCCESS',
    data,
  };
};

const getFinancialMetricsFail = () => {
  return {
    type: 'GET_FUND_FINANCIAL_METRICS_FAIL',
  };
};

export function getFinancialMetrics(fundId) {
  return (dispatch, getState) => {
    dispatch(getFinancialMetricsStart());

    const { login } = getState();

    axios
      .get(`${config.API_URL}/funds/metrics/read/${fundId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(getFinancialMetricsSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to get Finacial metrics', ex);
        dispatch(getFinancialMetricsFail());
      });
  };
}
