export default (
  state = {
    postTemplateSuccess: false,
    postTemplateFail: false,
    template: {},
  },
  action
) => {
  switch (action.type) {
    case 'POST_TEMPLATE_START':
      return {
        ...state,
        postTemplateSuccess: false,
        postTemplateFail: false,
      };

    case 'POST_TEMPLATE_SUCCESS':
      return {
        ...state,
        postTemplateSuccess: true,
        template: action.data,
      };

    case 'POST_TEMPLATE_FAIL':
      return { ...state, postTemplateFail: true };

    case 'RESET_TEMPLATE_STATUS':
      return {
        ...state,
        postTemplateSuccess: false,
        postTemplateFail: false,
        isUpdatingTemplateSuccess: false,
        isUpdatingTemplateFail: false
      };

    case 'GET_TEMPLATE_BY_ID_LOADING':
      return {
        ...state,
        isFetchingTemplateByID: true,
      };

    case 'GET_TEMPLATE_BY_ID':
      return {
        ...state,
        selectedTemplate: !action.error ? action.payload : {},
        isFetchingTemplateByID: false,
      };

    case 'UPDATE_TEMPLATE_LOADING':
      return {
        ...state,
        isUpdatingTemplate: true,
        isUpdatingTemplateSuccess: false,
        isUpdatingTemplateFail: false,
      };

    case 'UPDATE_TEMPLATE':
      return {
        ...state,
        updatedTemplate: !action.error ? action.payload : {},
        isUpdatingTemplate: false,
        isUpdatingTemplateSuccess: !action.error ? true : false,
        isUpdatingTemplateFail: action.error ? true : false,
      };

    case 'DELETE_TEMPLATE_LOADING':
      return {
        ...state,
        isDeleteingTemplate: true,
        isDeleteingTemplateSuccess: false,
        isDeleteingTemplateFail: false,
      };

    case 'DELETE_TEMPLATE':
      return {
        ...state,
        deletedTemplate: !action.error ? action.payload : {},
        isDeleteingTemplate: false,
        isDeleteingTemplateSuccess: !action.error ? true : false,
        isDeleteingTemplateFail: action.error ? true : false,
      };

    default:
      return state;
  }
};
