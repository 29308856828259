import React from 'react';

import './index.scss';

export default ({ text, icon, children }) => {
  return (
    <div className="blank-state">
      {icon}
      <h2 className="subtitle">{text}</h2>
      {children}
    </div>
  );
};
